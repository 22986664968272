import request from "@/utils/request";
//客户相关接口
/**
 * 客户等级列表
 */
export function getStoreLevel() {
  return request({
    url: "storeLevel/getAllList",
    method: "post",
  });
}

/**
 * 编辑客户级别信息
 */
export function editStoreLevel(data) {
  return request({
    url: "storeLevel/update",
    method: "post",
    data,
  });
}
/**
 * 删除客户级别
 */
export function delStoreLevel(data) {
  return request({
    url: "storeLevel/update",
    method: "post",
    data,
  });
}
/**
 * 新增、编辑客户
 */
export function updataClient(data) {
  return request({
    url: "StoreUser/updataClient",
    method: "post",
    data,
  });
}
/**
 * 客户列表
 */
export function getClientList(data) {
  return request({
    url: "StoreUser/getClientList",
    method: "post",
    data,
  });
}
/**
 * 批量删除客户
 */
export function delClient(data) {
  return request({
    url: "StoreUser/delClient",
    method: "post",
    data,
  });
}
/**
 * 客户详情
 */
export function clientDetail(data) {
  return request({
    url: "StoreUser/userDetail",
    method: "post",
    data,
  });
}

/**
 * 修改客户密码
 */
export function clientResetPassword(data) {
  return request({
    url: "StoreUser/resetPassword",
    method: "post",
    data,
  });
}
/**
 * 获取客户收货地址列表
 */
export function getClientAddress(data) {
  return request({
      url: "StoreUserAddress/getUserAddressList",
    method: "post",
    data,
  });
}
/**
 * 添加,编辑客户地址
 */
export function updataClientAddress(data) {
  return request({
    url: "StoreUserAddress/updataUserAddress",
    method: "post",
    data,
  });
}
/**
 *删除客户地址
 */
export function delClientAddress(data) {
  return request({
    url: "StoreUserAddress/delUserAddress",
    method: "post",
    data,
  });
}
/**
 * 客户标签列表
 */
export function getClienttagList(data) {
  return request({
    url: "StoreUserLabel/getUserLabelList",
    method: "post",
    data,
  });
}

/**
 * 客户沟通记录
 */
export function getCommunicationList(data) {
  return request({
    url: "UserCommunication/list",
    method: "post",
    data,
  });
}

/**
 * 新增沟通记录
 */
export function updateCommunication(data) {
  return request({
    url: "UserCommunication/update",
    method: "post",
    data,
  });
}

/**
 * 新增沟通记录
 */
export function getCommunicationDetail(data) {
  return request({
    url: "UserCommunication/detail",
    method: "post",
    data,
  });
}

/**
 * 删除沟通记录
 */
export function deleteCommunication(data) {
  return request({
    url: "UserCommunication/delete",
    method: "post",
    data,
  });
}

/**
 * 代表事项新增
 */

export function updateWait(data) {
  return request({
    url: "UserCommunication/updateWait",
    method: "post",
    data,
  });
}

/**
 * 代办事项列表
 */
export function getWaitList() {
  return request({
    url: "UserCommunication/waitList",
    method: "post",
  });
}
/**
 * 读代办事项
 */
export function deleteWait(id) {
  return request({
    url: "UserCommunication/deleteWait",
    method: "post",
    data: { id },
  });
}
