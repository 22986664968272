import request from "@/utils/request";

//上传文件相关接口

/**
 * 图片分组列表
 */
export function groupList(data = {}) {
  return request({
    url: "File/groupList",
    method: "post",
    data,
  });
}

/**
 * 图片列表
 */
export function getImg(data) {
  return request({
    url: "File/getImg",
    method: "post",
    data,
  });
}
/**
 * 视频列表
 */
export function getVideoList(data) {
  return request({
    url: "File/videoList",
    method: "post",
    data,
  });
}
/**
 * 添加文件组
 */
export function addGroup(data) {
  return request({
    url: "File/addGroup",
    method: "post",
    data,
  });
}
/**
 * 上传单个文件
 */
export function uploadFile(data) {
  return request({
    url: "file/uploadFile",
    method: "post",
    data,
  });
}
/**
 * 上传视频
 */
export function uploadVideo(data) {
  return request({
    url: "File/uploadVideo",
    method: "post",
    data,
  });
}
/**
 * 上传多个图片
 */
export function addImgs(data) {
  return request({
    url: "File/addImgs",
    method: "post",
    data,
  });
}
/**
 * 选择图片放入分组
 */
export function chooseImgToGroup(data) {
  return request({
    url: "File/chooseImgToGroup",
    method: "post",
    data,
  });
}
/**
 * 选择视频放入分组
 */
export function updateVideo(data) {
  return request({
    url: "File/updateVideo",
    method: "post",
    data,
  });
}
/**
 * 修改图片名字
 */
export function updateFileName(data) {
  return request({
    url: "File/updateFile",
    method: "post",
    data,
  });
}
/**
 * 删除图片
 */
export function delImage(data) {
  return request({
    url: "File/delImg",
    method: "post",
    data,
  });
}
/**
 * 删除文件组
 */
export function delGroup(data) {
  return request({
    url: "File/delGroup",
    method: "post",
    data,
  });
}

/**
 * 腾讯地图地址逆解析
 */
export function geocoder(location) {
  return request({
    url: "api/longitudeLatitudeToAddress",
    method: "post",
    data: {
      lat: location.lat,
      lng: location.lng,
    },
  });
}

/**
 * 获取省市区信息树形结构
 */
export function getCityTree() {
  return request({
    url: "CityData/getCityTree",
    method: "post",
  });
}

/**
 * 用户列表字段显示
 */
export function getTableRule(rule) {
  return request({
    url: "UserForm/detail",
    method: "post",
    data: { rule },
  });
}
/**
 * 用户列表字段修改新增
 */
export function addTableRule(rule, list_data) {
  return request({
    url: "UserForm/update",
    method: "post",
    data: { rule, list_data },
  });
}


// 商品导入图片
export function importImgZip(data) {
  return request({
    url: "goods/importImgZip",
    method: "post",
    data:data,
  });
}