<template>
  <div class="applea-component">
    <div class="applea-button" @click="appealShow = true">
      <div class="circle">
        <span>待办事项</span>
        <span class="appeal-number" v-if="count">{{ count > 99 ? "99+" : count }}</span>
      </div>
    </div>
    <el-dialog :visible.sync="appealShow" title="待办事项" width="1200px">
      <div class="flex flex-end" style="justify-content: space-between">
        <div class="flex">
          <el-select size="small" v-model="paramCp.status" placeholder="请选择待办状态" clearable>
            <el-option label="已处理" value="1"></el-option>
            <el-option label="未处理" value="2"></el-option>
          </el-select>
          <el-button class="ml-10" type="primary" size="small" @click="search">搜索</el-button>
        </div>
        <div class="flex">
          <el-button type="primary" size="small" @click="showAppealAdd">新增</el-button>
          <el-button size="small" @click="reset">重置</el-button>
        </div>
      </div>
      <el-table v-loading="loading" :data="list">
        <el-table-column label="内容" prop="content" min-width="200px" show-overflow-tooltip></el-table-column>
        <el-table-column label="图片" min-width="200px">
          <template slot-scope="{ row }">
            <el-image style="width: 50px; height: 50px" :preview-src-list="row.img_array.map((ite) => $store.getters.img_base_url + ite?.file_url)" v-for="(item, index) in row.img_array" :key="item.id" :src="$store.getters.img_base_url + item.file_url"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="{ row }">
            <el-tag :type="row.status == 1 ? 'warning' : 'primary'">
              {{ row.status == 2 ? "已处理" : "未处理" }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="被指派人" prop="wait_account[real_name]">
          <template slot-scope="{ row }">
            {{ row.wait_account.map((item) => item.real_name).join(",") }}
          </template>
        </el-table-column>
        <el-table-column label="指派人" prop="account[real_name]"></el-table-column>
        <el-table-column content="操作" width="300">
          <template slot-scope="scope">
            <div class="flex" style="padding: 10px 0">
              <el-button v-if="scope.row.status != 2 && isMe([scope.row.wait_account_id, ...scope.row.wait_account.map((item) => item.id)])" type="primary" size="mini" @click="resolveAppeal(scope.row)">处理</el-button>
              <el-button v-if="scope.row.status != 2 && scope.row.store_account_id == $store.state.user.storeInfo.account.id" type="warning" size="mini" @click="editAppeal(scope.row)">编辑</el-button>
              <el-badge v-if="scope.row.unread_count!=0" :value="scope.row.unread_count" class="item ml-10">
                <el-button type="warning" size="mini" @click="openCommunity(scope.row.id)">沟通记录</el-button>
              </el-badge>
              <el-button v-else type="warning" size="mini" @click="openCommunity(scope.row.id)">沟通记录</el-button>
            </div>
            <!--            <el-button type="primary" size="mini" @click="delAppeal(scope.row)"-->
            <!--              >删除-->
            <!--            </el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :current-page="page" :total="total" @current-change="changePage"></el-pagination>
    </el-dialog>
    <addAppeal ref="appealAdd" @getList="getAppealList"></addAppeal>
    <!--  沟通记录  -->
    <community ref="community" @confirm="getAppealList"></community>
  </div>
</template>
<script>
import request from "@/utils/request";
import addAppeal from "./appeal/add.vue";
import community from "@/layout/components/appeal/community.vue";

import { throttle } from "lodash";
// import appealPage from '../../views/'
export default {
  components: { addAppeal, community },
  data() {
    return {
      count: 0,
      appealShow: false,
      list: [],
      loading: false,
      page: 1,
      total: 0,
      param: {
        status: "",
      },
      paramCp: {},
    };
  },
  computed: {
    account() {
      return this.$store.state.user.storeInfo;
    },
  },
  created() {
    this.getAppealNumber();
    this.getAppealList();
  },
  methods: {
    search() {
      this.page = 1;
      this.param = JSON.parse(JSON.stringify(this.paramCp));
      this.getAppealList();
    },
    reset() {
      this.param = this.$options.data().param;
      this.paramCp = this.$options.data().param;
      this.page = 1;
      this.getAppealList();
    },
    // 打开会话
    openCommunity(id) {
      this.$refs.community.open(id);
    },
    // 编辑
    editAppeal(row) {
      this.$refs.appealAdd.edit(row);
    },
    changePage(e) {
      this.page = e;
      this.getAppealList();
    },
    async getAppealList() {
      this.loading = true;
      await request({
        url: "UserCommunication/waitList",
        method: "post",
        data: {
          page: this.page,
          offset: 10,
          ...this.param,
        },
      }).then((res) => {
        this.list = res.data.list;
        this.total = res.data.count;
      });
      this.loading = false;
    },
    getAppealNumber() {
      request({
        url: "UserCommunication/findUncheckWaitList",
        method: "get",
      }).then((res) => {
        this.count = res.data.count;
      });
    },
    isMe(id) {
      return id.includes(this.account.account.id);
    },
    resolveAppeal: throttle(
      function (row) {
        this.$confirm("确认已解决该事项？改操作不可撤回", "提示")
          .then(() => {
            request({
              url: "UserCommunication/setStatus",
              method: "post",
              data: {
                id: row.id,
                status: 2,
              },
            }).then((res) => {
              this.$message.success("处理成功");
              this.getAppealList();
              this.getAppealNumber();
            });
          })
          .catch(() => {});
      },
      1000,
      {
        leading: true,
        trailing: false,
      }
    ),
    delAppeal(row) {
      request({
        url: "UserCommunication/deleteWait",
        method: "post",
        data: {
          id: row.id,
        },
      }).then((res) => {
        this.$message.success(res.data.msg);
        this.getAppealList();
        this.getAppealNumber();
      });
    },
    showAppealAdd() {
      this.$refs.appealAdd.dialogVisible = true;
    },
  },
};
</script>
<style>
.applea-button {
  position: fixed;
  bottom: 100px;
  z-index: 99;
  color: white;
  text-align: center;
  line-height: 80px;
  padding-left: 50px;
  right: -60px;
  transition: all 0.3s;
}

.applea-button:hover {
  right: 0px;
  padding-right: 20px;
}

.circle {
  height: 80px;
  width: 80px;
  background: #409eff;
  border-radius: 50%;
  position: relative;
}

.appeal-number {
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  background: red;
  border-radius: 50%;
  color: white;
  text-align: center;
  line-height: 28px;
  font-size: 12px;
}
</style>
