<template>
  <el-image :src="src ? currentSrc : noPic" :fit="fit" @error="imgError"></el-image>
</template>
<script>
export default {
  props: {
    src: {
      type: String,
      default: "",
    },
    fit: {
      type: String,
      default: "",
    },
  },
  watch: {
    src: {
      immediate: true,
      handler(val) {
        this.currentSrc = this.$store.getters.base_url + val;
      },
    },
  },
  data() {
    return {
      currentSrc: null,
      noPic: "https://admin.jxnmw.cn/static/images/goods.png",
    };
  },
  methods: {
    imgError(c) {
      this.currentSrc = this.noPic;
      return true;
    },
  },
};
</script>
