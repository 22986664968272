import Vue from 'vue'
import Pinyin from "@/utils/ChinesePY"; //中文转拼音库
Vue.prototype.$Pinyin = Pinyin;
//分页组件
import Page from "@/components/page";
Vue.component("w-page", Page);
//多功能选择器组件
import wselect from "@/components/wselect";
Vue.component("w-select", wselect);
//表格组件
import wtable from "@/components/wtable";
Vue.component("w-table", wtable);

import image from '@/components/image'
Vue.component('w-image', image)