<template>
  <div ref="draggableContainer">
    <slot />
  </div>
</template>

<script>
import Sortable from "sortablejs";

export default {
  name: "Draggable",

  mounted() {
    this.initDraggble();
  },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    initDraggble() {
      const _this = this;
      const sortable = Sortable.create(this.$refs.draggableContainer, {
        animation: 300,
        dragClass: "dragClass", //设置拖拽样式类名
        ghostClass: "ghostClass", //设置拖拽停靠样式类名
        chosenClass: "chosenClass", //设置选中样式类名
        ...this.options,
        // 列表内元素顺序更新的时候触发
        onUpdate: function (/**Event*/ evt) {
          console.log(evt);
          const { value } = _this;
          const oldIndex = evt.oldDraggableIndex;
          const newIndex = evt.newDraggableIndex;
          const list = _this.value.slice();
          const item = list.splice(oldIndex, 1)[0];
          list.splice(newIndex, 0, item);
          _this.$emit("input", list);
        },
      });
    },
  },
};
</script>

<style lang="sass" scoped></style>
