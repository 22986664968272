import { getButtonAuth, getInfo, getUserAsStore, login } from "@/api/user";
import { getStoreToken, getToken, removeToken, setToken } from "@/utils/auth";
import { asyncRoutes, constantRoutes, resetRouter } from "@/router";
import { clearCache } from "@/utils/buttonAuth";
import request from "@/utils/request";

const getDefaultState = () => {
  return {
    storeToken: getStoreToken(), //刷新之后获取商店Token
    token: getToken(), // 刷新之后获取Token
    name: "",
    avatar: "",
    storeInfo: {},
    userInfo: null,
    routers: null,
    butAuth: [], //功能权限表
    auth: [],
    config: null,
    userLevel: []
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_STORE_TOKEN: (state, token) => {
    state.storeToken = token;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_ROUTER: (state, routers) => {
    console.log(routers);
    state.routers = routers;
  },
  SET_BUTAUTH: (state, butList) => {
    state.butAuth = butList;
  },
  SET_STOREINFO: (state, storeInfo) => {
    state.storeInfo = storeInfo;
  },
  SET_AUTH: (state, auth) => {
    state.auth = auth;
  },
  SET_CONFIG: (state, config) => {
    state.config = config;
  },
  SET_USERLEVEL: (state, userLevel) => {
    state.userLevel = userLevel;
  },
};

const actions = {
  /**
   * 切换店铺,删除店铺信息
   */
  switchStore({ commit, state }) {
    return new Promise((resolve) => {
      commit("SET_STORE_TOKEN", null);
      commit("SET_STOREINFO", {});
      removeToken("store_token");
      resolve();
    });
  },
  /**
   * 获取商店信息,权限列表
   */
  getStoreDetail({ commit, state }, token) {
    commit("SET_STORE_TOKEN", token);
    setToken("store_token", token); //设置当前选择的商店token
    return new Promise((reslove, reject) => {
      getUserAsStore()
        .then((res) => {
          commit("SET_STOREINFO", res.data);
          reslove();
        })
        .catch((res) => {
          //获取商店信息失败，删除上一步存的商店信息及token
          commit("SET_STORE_TOKEN", null);
          commit("SET_STOREINFO", {});
          removeToken("store_token");
          reject(res);
        });
    });
  },
  /**
   * 菜单权限验证
   */
  filterMenuAuth({ commit, state }, router) {
    if (router.meta.routerId) {
      const { storeInfo } = state;
      if (storeInfo.auth.is_admin == 2) {
        if (storeInfo.auth.auths.filter((item) => item.menu_id == router.meta.routerId).length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  },
  /**
   * 功能权限验证
   * @param commit
   * @param authCode
   */
  authVerification({ commit, state }, authCode) {
    if (state.storeInfo.auth.is_admin == 1) {
      return true;
    }
    if (state.butAuth.findIndex((item) => item.fieldName == authCode) == -1) {
      return false;
    } else {
      return true;
    }
  },
  /**
   * 获取页面按钮权限表
   */
  getButtonPermissions({ commit, state }, key) {
    return new Promise((resolve, reject) => {
      getButtonAuth({ keyword: key })
        .then((res) => {
          commit("SET_BUTAUTH", res.data);
          resolve();
        })
        .catch((res) => {
          reject(res);
        });
    });
  },
  /**
   * @description: 用户登录
   */
  login({ commit }, userInfo) {
    const { username, password } = userInfo;
    return new Promise((resolve, reject) => {
      login({ store_account: username.trim(), password: password })
        .then(async (response) => {
          const { data } = response;
          commit("SET_TOKEN", data.token);
          setToken("user_token", data.token); // 设置Token
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * 合并异步路由，生成最终路由表
   * @param commit
   * @param state
   * @returns {Promise<unknown>}
   * @constructor
   */
  GenerateRoutes({ commit, state }) {
    return new Promise((reslove, reject) => {
      let routers = [...constantRoutes, ...asyncRoutes];
      commit("SET_ROUTER", routers);
      reslove(asyncRoutes);
    });
  },
  // 获取用户信息
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then((response) => {
          const { data } = response;
          if (!data) {
            return reject("验证失败，请重新登录");
          }
          const { account, avatar } = data;
          commit("SET_NAME", account);
          commit("SET_AVATAR", avatar);
          commit("SET_USERINFO", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 退出登录
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      removeToken(); // 必须先删除token
      resetRouter();
      clearCache();
      localStorage.removeItem("vuex");
      commit("RESET_STATE");
      resolve();
    });
  },

  // 删除token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      removeToken(); // 必须先删除token
      commit("RESET_STATE");
      resolve();
    });
  },
  async getAuth({ commit, state }) {
    let { data } = await request.post("/store/getAccountAuth");
    commit("SET_AUTH", data.auths);
  },
  async getConfig({ commit, state }) {
    let { data } = await request.post('/Setting/settingDetail')
    commit('SET_CONFIG', data)
  },
  async getUserLevel({ commit }) {
    let { data } = await request.post("/storeLevel/getAllList");
    commit("SET_USERLEVEL", data);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
