<template>
    <el-popover placement="top" trigger="hover" :content="title">
        <el-link slot="reference">
            {{ title }}
        </el-link>
    </el-popover>
</template>
<script>
export default {
    name: 'popover',
    props: {
        title: String,
    }
}
</script>