import Cookies from 'js-cookie'
import store from '@/store'
const TokenKey = 'admin_token'

export function getToken() {
    if (Cookies.get('user_token')) {
        return Cookies.get('user_token')
    }
    return undefined
}

export function getStoreToken() {
    if (Cookies.get('store_token')) {
        return Cookies.get('store_token')
    }
    return undefined
}

export function setToken(TokenKey, token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken(TokenKey) {
    if (TokenKey) {
        Cookies.remove(TokenKey)
    } else {
        if (Cookies.get('user_token')) {
            Cookies.remove('user_token')
        }
        if (Cookies.get('store_token')) {
            Cookies.remove('store_token')
        }
        return undefined
    }
}

