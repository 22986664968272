<template>
  <section class="app-main">
    <div class="app-main_box">
      <transition name="fade-transform" mode="out-in">
        <keep-alive :include="keepAlive">
          <router-view :key="key" />
        </keep-alive>
      </transition>
    </div>
  </section>
</template>

<script>
export default {
  name: "AppMain",
  computed: {
    key() {
      return this.$route.path;
    },
    keepAlive() {
      return this.$store.state.app.keepAlive
    }
  },
};
</script>

<style scoped>
.app-main_box {
  position: relative;
  margin: 10px 20px 0;
  padding: 20px;
  background: #fff;
  height: calc(100vh - 70px);
  overflow-y: scroll;
}

.app-main {
  background: #f5f5f5;
  /* 50= navbar  50  */
  min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  overflow: hidden;
}

.fixed-header+.app-main {
  padding-top: 50px;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
