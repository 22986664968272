<template>
  <!--  多功能单选器，可指定传入数据的key label来渲染值，-->
  <div>
    <el-select v-model="selected_t" :placeholder="placeholder" size="small" style="width: 100%" @change="changes">
      <el-option
        v-for="item in options"
        :key="item[renderProps.key]"
        :label="item[renderProps.label]"
        :value="item[renderProps.key]">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "wselect",
  props: {
    //选择器渲染的key和label，可自定义。默认为id和title
    renderProps: {
      type: Object,
      default: () => {
        return {
          key: 'id',
          label: 'title'
        }
      }
    },
    //选择器类型，根据不同类型拉去不同数据源
    optionsType: String,
    //选择器placeholder
    placeholder: {
      type: String,
      default: ''
    },
    //选择器初始化数据，默认显示选中项
    selected: {
      type: [Number, String],
      default: ''
    }
  },
  model: {
    prop: 'selected',
    event: 'set_selected'
  },
  data() {
    return {
      options: [{
        id: '选项1',
        title: '黄金糕'
      }, {
        id: '选项2',
        title: '双皮奶'
      }, {
        id: '选项3',
        title: '蚵仔煎'
      }, {
        id: '选项4',
        title: '龙须面'
      }, {
        id: '选项5',
        title: '北京烤鸭'
      }],
      selected_t: this.selected,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    changes(e) {
      this.$emit('set_selected', this.selected_t)
    },
    getData() {

    }
  }
}
</script>

<style lang="scss" scoped>

</style>
