<template>
  <el-dialog class="community-page" :visible.sync="visible" title="沟通记录" width="1000px">
    <el-scrollbar style="height: 60vh" v-loading="loading">
      <el-timeline>
        <el-timeline-item v-for="item in list" :key="`community-${item.id}`" :timestamp="item.create_time.split(' ')[0]" placement="top">
          <el-card>
            <p>{{ item.content }}</p>
            <div class="flex" style="gap: 10px" v-if="item.imgs">
              <div v-for="(ite, index) in item.imgs?.split(',')" :key="`replyImg-content-${ite}`" style="position: relative">
                <el-image :src="$store.getters.img_base_url + ite" fit="cover" :preview-src-list="item.imgs?.split(',').map((item) => $store.getters.img_base_url + item)" style="height: 50px; width: 50px"></el-image>
              </div>
            </div>
            <div>
              <p v-for="ite in item.files" :key="`file-${item.files}`">
                <el-link @click="openFile(ite.url)">附件：{{ ite.name }}</el-link>
              </p>
            </div>
            <p>{{ item.account.real_name }} 提交于 {{ item.create_time }}</p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
      <el-empty description="暂无对话" v-if="!list.length"></el-empty>
    </el-scrollbar>
    <div class="reply-content">
      <div>
        <i class="el-icon-picture-outline" style="font-size: 20px; padding: 6px; cursor: pointer" @click="chooseImg"></i>
        <i class="el-icon-folder-opened" style="font-size: 20px; padding: 6px; cursor: pointer" @click="chooseFiles"></i>
      </div>
      <uoloadImg v-show="false" v-model="imgs" ref="uploadImg" :limit="9" multiple></uoloadImg>
      <uploadFile v-show="false" v-model="files" ref="uploadFile" :limit="6" multiple></uploadFile>
      <el-input v-model="content" type="textarea" :rows="5" resize="none" placeholder="请填写会话"></el-input>
      <el-button @click="reply" @keydown.enter="reply" class="reply-btn" size="small" type="primary">回复</el-button>
      <div style="position: absolute; left: 10px; bottom: 10px">
        <div style="display: flex; gap: 10px" v-if="imgs.length">
          <div v-for="(item, index) in imgs" :key="`replyImg-${item.url}`" style="position: relative">
            <i class="el-icon-error" style="position: absolute; right: -10px; top: -10px" @click="imgs.splice(index, 1)"></i>
            <el-image :src="item.url" fit="cover" :preview-src-list="imgs.map((item) => item.url)" style="height: 50px; width: 50px"></el-image>
          </div>
        </div>
        <div class="mt-10">
          <div v-for="item in files" :key="`file-${item.url}`">
            <el-link @click="openFile(item.url)">附件：{{ item.name }}</el-link>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import request from "@/utils/request";
import uoloadImg from "@/components/upload-image.vue";
import uploadFile from "@/components/Upload/uploadFile.vue";

export default {
  components: { uoloadImg, uploadFile },
  data() {
    return {
      visible: false,
      list: [],
      content: "",
      wait_id: "",
      imgs: [],
      loading: false,
      files: [],
    };
  },
  methods: {
    open(id) {
      this.wait_id = id;
      this.getList(id);
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    chooseImg() {
      this.$refs.uploadImg.$refs.uploadBtn.click();
    },
    chooseFiles() {
      this.$refs.uploadFile.$refs.uploadBtn.$el.click();
    },
    openFile(url) {
      window.open(this.$store.getters.img_base_url + url);
    },
    async reply() {
      if (!this.content) {
        this.$message.error("请输入回复内容");
        return;
      }
      try {
        this.$loading();
        await request.post("/UserCommunication/addWaitDialogue", {
          wait_id: this.wait_id,
          imgs: this.imgs.map((item) => item.file_path).join(","),
          content: this.content,
          files: this.files,
        });

        this.list.unshift({
          account: {
            real_name: this.$store.state.user.storeInfo.account.real_name,
          },
          content: this.content,
          imgs: this.imgs.map((item) => item.file_path).join(","),
          create_time: new Date().toLocaleString(),
          files: this.files,
        });
        this.files = [];
        this.content = "";
        this.imgs = [];
        this.$emit("confirm");
      } catch (err) {
      } finally {
        this.$loading().close();
      }
    },
    async getList(id) {
      this.loading = true;
      let res = await request.post("/UserCommunication/waitDialogueList", {
        wait_id: id,
      });
      this.list = res.data;
      this.loading = false;
    },
  },
};
</script>
<style lang="scss">
.community-page {
  .el-dialog {
    margin-top: 20px !important;
  }

  .reply-content {
    position: relative;

    .el-textarea__inner {
      padding-bottom: 60px;
    }

    .reply-btn {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }
}
</style>
