import request from "@/utils/request";
/**
 * 登录
 * @param data
 * @returns {AxiosPromise}
 */
export function login(data) {
  return request({
    url: "login/login",
    method: "post",
    data,
  });
}
/**
 * 获取用户信息
 * @param data
 * @returns {AxiosPromise}
 */
export function getInfo() {
  return request({
    url: "store/getAccountInfo",
    method: "post",
  });
}
/**
 * 退出登录
 * @param data
 * @returns {AxiosPromise}
 */
export function logout() {
  return request({
    url: "user/logout",
    method: "post",
  });
}
/**
 * 获取页面按钮权限表
 * @returns {AxiosPromise}
 */
export function getButtonAuth(data) {
  return request({
    url: "store/getButtonAuth",
    method: "post",
    data,
  });
}

/**
 * 获取用户在当前商店的信息
 * @returns {AxiosPromise}
 */
export function getUserAsStore() {
  return request({
    url: "store/getStoreInfo",
    method: "post",
  });
}

/**
 * 获取线下收款账户列表
 */
export function getofflineaccpuntlist(data) {
  return request({
    url: "Capital/accountList",
    method: "post",
    data,
  });
}
/**
 * 获取添加/编辑线下收款账户列表
 */
export function addofflineaccpuntlist(data) {
  return request({
    url: "capital/addBankAccount",
    method: "post",
    data,
  });
}
/**
 * 获取删除线下收款账户列表
 */
export function delofflineaccpuntlist(data) {
  return request({
    url: "capital/deleteBank",
    method: "post",
    data,
  });
}

/**
 * 客户标签列表
 */
// export function  getClienttagList(data) {
//   return request({
//     url: 'StoreUserLabel/getUserLabelList',
//     method: 'post',
//     data
//   })
// }
/**
 * 添加客户标签
 */
// export function  getClientaddtagList(data) {
//   return request({
//     url: 'StoreUserLabel/addUserLabel',
//     method: 'post',
//     data
//   })
// }
/**
 * 编辑客户列表标签
 */
// export function  getClientedittagList(data) {
//   return request({
//     url: 'StoreUserLabel/editUserLabel',
//     method: 'post',
//     data
//   })
// }

/**
 * 删除客户列表标签
 */
// export function  getClienttagListdelete(data) {
//   return request({
//     url: 'StoreUserLabel/delUserLabel',
//     method: 'post',
//     data
//   })
// }

/**
 * 获取新增收款单
 */
export function addreceiptlist(data) {
  return request({
    url: "capital/add",
    method: "post",
    data,
  });
}
