<template>
  <div :class="{ 'has-logo': showLogo }">
    <logo :collapse="isCollapse" />
    <ul class="v-menu" ref="menu">
      <li class="v-menu__item" v-for="item in menuList" :key="item.title" @click="toMenu(item)">
        <i style="font-size: 14px; color: white" class="svg-icon" :class="item.icon"></i>
        <span class="v-menu__item-title" v-show="!isCollapse">{{ item.title }}</span>
        <ul
          class="v-menu__popup"
          v-if="item.type == 1"
          style="z-index: 2001 !important"
          :style="{
            top: `${top}px`,
            left: !isCollapse ? '205px' : `55px`,
          }"
        >
          <li class="v-menu__group">
            <ul class="v-menu__list">
              <li v-show="$getRouteAuth(ite.web_url).get('visit') ? true : false" v-for="ite in item.children" class="v-menu__list-title" @click="$router.push(ite.web_url)">
                {{ ite.title }}
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import variables from "@/styles/variables.scss";

export default {
  components: { SidebarItem, Logo },
  data() {
    return {
      top: 0,
    };
  },
  mounted() {
    this.addMouseListener();
    this.getMenu();
  },
  beforeDestroy() {
    this.removeMouseListener();
  },
  methods: {
    toMenu(item) {
      if (item.type == 2) {
        this.$router.push(item.web_url);
      }
    },
    async getMenu() {
      await this.$store.dispatch("user/getAuth");
      this.removeMouseListener();
      this.addMouseListener();
    },
    removeMouseListener() {
      const menuItems = document.getElementsByClassName("v-menu__item");
      for (const menuItem of menuItems) {
        menuItem.removeEventListener("mouseenter", this.mouseListener);
      }
    },
    addMouseListener() {
      const menuItems = document.getElementsByClassName("v-menu__item");
      for (const menuItem of menuItems) {
        menuItem.addEventListener("mouseenter", this.mouseListener);
      }
    },
    mouseListener(event) {
      const target = event.target;
      const menuPopup = target.getElementsByClassName("v-menu__popup")[0];
      if (menuPopup) {
        let top = target.offsetTop - this.$refs.menu.scrollTop;
        if (top + menuPopup.clientHeight > window.innerHeight) {
          top = window.innerHeight - menuPopup.clientHeight - 20;
        }
        this.top = top;
        if (target.offsetTop <= window.innerHeight / 2) {
          menuPopup.classList.add("v-menu__popup--top");
          menuPopup.classList.remove("v-menu__popup--bottom");
        } else {
          menuPopup.classList.remove("v-menu__popup--top");
          menuPopup.classList.add("v-menu__popup--bottom");
        }
      }
    },
  },
  computed: {
    ...mapGetters(["sidebar", "routers"]),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
    hasPermission() {
      try {
        let { is_super, is_admin } = this.$store.state.user.storeInfo.account;
        return this.$store.state.user && is_admin == 1 && is_super == 1;
      } catch {
        return false;
      }
    },
    menuList() {
      return this.$store.state.user.auth;
    },
  },
};
</script>

<style>
.v-menu {
  //width: fit-content; height: 100%; margin: 0; padding: 0; list-style: none; background-color: rgb(48, 65, 86); overflow-y: scroll;
}

.v-menu__item {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  cursor: pointer;
  padding-left: 20px;
  z-index: 2001;
}

.v-menu__item:hover .v-menu__popup {
  display: flex;
}

.v-menu__item-icon {
  color: rgb(191, 203, 217);
  font-size: 14px;
}

.v-menu__item-icon[class*="el-"] {
  margin-right: 16px;
}

.v-menu__item-title {
  width: 160px;
  color: rgb(191, 203, 217);
  font-size: 14px;
}

.v-menu__item:hover {
  background-color: #263445;
}

.v-menu__popup {
  z-index: 2001 !important;
  position: fixed;
  left: 10px;
  display: none;
  width: fit-content;
  height: fit-content;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: rgb(48, 65, 86);
}

.v-menu__popup--top {
  top: 0;
}

.v-menu__popup--bottom {
  bottom: 0;
}

.v-menu__popup::before {
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  width: 10px;
  height: 100%;
}

.v-menu__group {
  box-sizing: border-box;
  width: 200px;
  padding: 0;
  margin: 0;
  border-right: 1px solid rgb(191, 203, 217);
}

.v-menu__group:last-child {
  border-right: none;
}

.v-menu__group-title {
  color: rgb(191, 203, 217);
  font-weight: bold;
  line-height: 40px;
  margin-bottom: 10px;
}

.v-menu__list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.v-menu__list-title {
  padding-left: 20px;
  color: rgb(191, 203, 217);
  font-size: 14px;
  line-height: 50px;
}

.v-menu__list-title:hover {
  background-color: #263445;
}
</style>
