import Vue from "vue";
import Router from "vue-router";
import store from "@/store/index";
/* Layout */
import Layout from "@/layout";

Vue.use(Router);

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                  当设置 true 的时候该路由不会在侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true              当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                               只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                               若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                               你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 *
 * redirect: noRedirect           当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    roles: ['admin','editor']    设置该路由进入的权限，支持多个权限叠加
    title: 'title'               设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'/'el-icon-x' 设置该路由的图标，支持 svg-class，也支持 el-icon-x element-ui 的 icon
    noCache: true                如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    affix: true                  若果设置为true，它则会固定在tags-view中(默认 false)
    breadcrumb: false            如果设置为false，则不会在breadcrumb面包屑中显示(默认 true)
    activeMenu: '/example/list'  当路由设置了该属性，则会高亮相对应的侧边栏。
                                 这在某些场景非常有用，比如：一个文章的列表页路由为：/article/list
                                 点击文章进入文章详情页，这时候路由为/article/1，但你想在侧边栏高亮文章列表的路由，就可以进行如下设置
                                activeMenu: '/article/list'
    routerId:number              标识路由的id，作为路由权限的媒介。后端返回当前账号的路由id组，当去往的路由存在于路由id组中，标识有当前路由的权限
                                 否则禁止进入
  }
 */

/**
 * constantRoutes
 * 所有权限通用路由表
 * 如首页和登录页和一些不用权限的公用页面
 */
export const constantRoutes = [
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true,
  },
  {
    path: "/404",
    component: () => import("@/views/404"),
    hidden: true,
  },
  {
    path: "/selectStore",
    component: () => import("@/views/selectStore/index"),
    hidden: true,
  },
  /*  404 页面必须放在最后 !!! */
  // {path: '*', redirect: '/404', hidden: true}
];
/**
 * 异步路由表，权限控制显示
 * @type {any}
 */
export const asyncRoutes = [
  {
    path: "/",
    component: Layout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("@/views/dashboard/index"),
        meta: {
          title: "首页",
          icon: "dashboard",
        },
      },
    ],
  },
  // {
  //   path: "/permission_setting",
  //   component: Layout,
  //   redirect: "/permission_setting/index",
  //   name: "permission_setting",
  //   meta: {
  //     title: "添加权限",
  //     icon: "el-icon-box",
  //   },
  //   children: [
  //     {
  //       path: "index",
  //       name: "index",
  //       component: () =>
  //         import("@/views/permission_setting/permission_setting"),
  //       meta: {
  //         title: "添加权限",
  //         icon: "el-icon-s-custom",
  //       },
  //     },
  //   ],
  // },
  {
    path: "/commodity",
    component: Layout,
    redirect: "/commodity/list",
    alwaysShow: true,
    name: "commodity",
    meta: {
      title: "商品管理",
      icon: "el-icon-s-goods",
    },
    beforeEnter: (to, from, next) => {
      //获取此大类下的所有页面的按钮权限
      store.dispatch("user/getButtonPermissions", "PRODUCT").then((res) => {
        next();
      });
    },
    children: [
      {
        path: "list",
        component: () => import("@/views/commodity/list"),
        name: "list",
        meta: {
          title: "商品列表",
          routerId: "1",
        },
      },
      {
        path: "updata",
        component: () => import("@/views/commodity/updata"),
        name: "updata",
        hidden: true,
        beforeEnter: (to, from, next) => {
          if (to.query.id) {
            to.meta.title = "编辑商品";
          } else {
            to.meta.title = "新建商品";
          }
          next();
        },
        meta: {
          title: "商品",
          routerId: "2",
        },
      },
      {
        path: "goods_detail",
        component: () => import("@/views/commodity/detail/index"),
        name: "goods_detail",
        hidden: true,
        meta: {
          title: "商品详情",
          routerId: "3",
        },
      },
      {
        path: "headquarters_goods",
        component: () => import("@/views/commodity/headquarters_goods"),
        name: "headquarters_goods",
        hidden: true,
        meta: {
          title: "总部商品库",
          routerId: "3",
        },
      },
      {
        path: "collect_goods",
        component: () => import("@/views/commodity/collect_goods/index"),
        name: "collect_goods",
        hidden: true,
        meta: {
          title: "采集商品",
          routerId: "3",
        },
      },
      {
        path: "product_import_base_data",
        component: () => import("@/views/commodity/product_import_base_data"),
        name: "product_import_base_data",
        meta: {
          title: "导入商品基础信息",
          routerId: "4",
        },
        hidden: true,
      },
      {
        path: "product_import_goods_img",
        component: () => import("@/views/commodity/product_import_goods_img"),
        name: "product_import_goods_img",
        meta: {
          title: "导入商品图片",
          routerId: "4",
        },
        hidden: true,
      },
      {
        path: "classify",
        component: () => import("@/views/commodity/classify"),
        name: "classify",
        meta: {
          title: "商品分类",
          routerId: "5",
        },
      },
      {
        path: "brand",
        component: () => import("@/views/commodity/brand"),
        name: "brand",
        meta: {
          title: "商品品牌",
          routerId: "6",
        },
      },
      {
        path: "Brandtag",
        component: () => import("@/views/commodity/Brandtag"),
        name: "Brandtag",
        meta: {
          title: "商品标签",
          routerId: "6",
        },
      },
      {
        path: "unit",
        component: () => import("@/views/commodity/unit"),
        name: "unit",
        meta: {
          title: "计量单位",
          routerId: "7",
        },
      },
      {
        path: "product_order_price",
        component: () => import("@/views/commodity/product_order_price"),
        name: "product_order_price",
        meta: {
          title: "导入商品订货价",
          routerId: "8",
        },
        hidden: true,
      },
      {
        path: "product_customer_pricing",
        component: () => import("@/views/commodity/product_customer_pricing"),
        name: "product_customer_pricing",
        meta: {
          title: "导入商品客户定价",
          routerId: "9",
        },
        hidden: true,
      },
    ],
  },
  {
    path: "/order",
    component: Layout,
    redirect: "/order/administration/purchase_order",
    alwaysShow: true,
    name: "order",
    meta: {
      title: "订单管理",
      icon: "el-icon-s-order",
    },
    children: [
      {
        path: "administration/purchase_order",
        component: () => import("@/views/order/administration/purchase_order"),
        name: "purchase_order",
        meta: {
          title: "订货单",
          routerId: "9",
        },
      },
      {
        path: "administration/return_order",
        component: () => import("@/views/order/administration/return_order"),
        name: "return_order",
        meta: {
          title: "退货单",
          routerId: "9",
        },
      },
      {
        path: "administration/return_order_detail",
        component: () => import("@/views/order/return_order_detail/index"),
        name: "return_order_detail",
        meta: {
          title: "退货单详情",
        },
      },
      {
        path: "/administration/outgoodsrecord",
        component: () => import("@/views/order/administration/outgoodsrecord"),
        name: "outgoodsrecord",
        meta: {
          title: "出库发货记录",
          routerId: "9",
        },
      },
      {
        path: "/administration/ordgoodstotal_info",
        component: () => import("@/views/order/administration/ordgoodstotal_info"),
        name: "ordgoodstotal_info",
        meta: {
          title: "订单商品统计（按明细）",
          routerId: "9",
        },
      },
      {
        path: "/administration/ordgoodstotal_user",
        component: () => import("@/views/order/administration/ordgoodstotal_user"),
        name: "ordgoodstotal_user",
        meta: {
          title: "订单商品统计（按客户）",
          routerId: "9",
        },
      },
      {
        path: "/administration/ordgoodstotal_goods",
        component: () => import("@/views/order/administration/ordgoodstotal_goods"),
        name: "ordgoodstotal_goods",
        meta: {
          title: "订单商品统计（按商品）",
          routerId: "9",
        },
      },
      {
        path: "/administration/ordergoodstotal_supplier",
        component: () => import("@/views/order/administration/ordergoodstotal_supplier"),
        name: "ordergoodstotal_supplier",
        meta: {
          title: "订单商品统计（按供应商）",
          routerId: "9",
        },
      },
      {
        path: "/administration/delivergoods_total",
        component: () => import("@/views/order/administration/delivergoods_total"),
        name: "delivergoods_total",
        meta: {
          title: "发货统计",
          routerId: "9",
        },
      },
      {
        path: "/administration/backordertotal",
        component: () => import("@/views/order/administration/backordertotal"),
        name: "backordertotal",
        meta: {
          title: "退单商品统计",
          routerId: "9",
        },
      },

      {
        path: "/administration/deliverytask",
        component: () => import("@/views/order/administration/deliverytask"),
        name: "deliverytask",
        hidden: true,
        meta: {
          title: "配送任务",
          routerId: "9",
        },
      },
      {
        path: "orderDetail/index",
        component: () => import("@/views/order/orderDetail/index"),
        name: "orderDetail",
        hidden: true,
        meta: {
          title: "订单详情",
          routerId: "9",
        },
      },
      {
        path: "administration/add_purchase_order",
        component: () => import("@/views/order/administration/add_purchase_order"),
        name: "add_purchase_order",
        hidden: true,
        meta: {
          title: "新增订货单",
          routerId: "9",
        },
      },

      {
        path: "administration/edit_purchase_order",
        component: () => import("@/views/order/administration/edit_purchase_order"),
        name: "edit_purchase_order",
        hidden: true,
        meta: {
          title: "修改订货单",
          routerId: "9",
        },
      },
      {
        path: "administration/add_return_order",
        component: () => import("@/views/order/administration/add_return_order"),
        name: "add_return_order",
        hidden: true,
        meta: {
          title: "新增退货单",
          routerId: "9",
        },
      },
    ],
  },
  {
    path: "/stock",
    component: Layout,
    redirect: "/stock/commodityInventory",
    alwaysShow: true,
    name: "stock",
    meta: {
      title: "库存管理",
      icon: "el-icon-s-grid",
    },
    children: [
      {
        path: "tableComponent",
        component: () => import("@/views/stock/tableComponent"),
        name: "tableComponent",
        hidden: true,
        meta: {
          title: "测试表格组件",
          routerId: "10",
        },
      },
      {
        path: "commodityInventory",
        component: () => import("@/views/stock/commodityInventory"),
        name: "commodityInventory",
        meta: {
          title: "商品库存",
          routerId: "10",
        },
      },
      {
        path: "warehousing",
        component: () => import("@/views/stock/warehousing"),
        name: "warehousing",
        meta: {
          title: "商品入库",
          routerId: "11",
        },
      },
      {
        path: "add_warehousing",
        component: () => import("@/views/stock/add_warehousing"),
        name: "add_warehousing",
        hidden: true,
        meta: {
          title: "新增商品入库",
          routerId: "11",
        },
      },
      {
        path: "warehousing_detail",
        component: () => import("@/views/stock/warehousing_detail"),
        name: "warehousing_detail",
        beforeEnter: (to, from, next) => {
          if (to.query.type == 1) {
            to.meta.title = "商品入库明细";
          } else {
            to.meta.title = "商品出库明细";
          }
          next();
        },
        hidden: true,
        meta: {
          routerId: "11",
        },
      },
      {
        path: "exWarehouse",
        component: () => import("@/views/stock/exWarehouse"),
        name: "exWarehouse",
        meta: {
          title: "商品出库",
          routerId: "12",
        },
      },
      {
        path: "add_exwarehouse",
        component: () => import("@/views/stock/add_exwarehouse"),
        name: "add_exwarehouse",
        hidden: true,
        meta: {
          title: "新增商品出库",
          routerId: "12",
        },
      },
      {
        path: "allocation",
        component: () => import("@/views/stock/allocation"),
        name: "allocation",
        meta: {
          title: "库存调拨",
          routerId: "13",
        },
      },
      {
        path: "allocation_detail",
        component: () => import("@/views/stock/allocation_detail"),
        hidden: true,
        name: "allocation_detail",
        meta: {
          title: "库存调拨详情",
          routerId: "13",
        },
      },
      {
        path: "add_allocation",
        component: () => import("@/views/stock/add_allocation"),
        name: "add_allocation",
        hidden: true,
        meta: {
          title: "新增库存调拨",
          routerId: "13",
        },
      },
      {
        path: "Inventory",
        component: () => import("@/views/stock/Inventory"),
        name: "Inventory",
        hidden: true,
        meta: {
          title: "库存盘点",
          routerId: "14",
        },
      },
      {
        path: "receipt_issue_details",
        component: () => import("@/views/stock/receipt_issue_details"),
        name: "receipt_issue_details",
        meta: {
          title: "出入库明细",
          routerId: "15",
        },
      },
      // {
      //   path: "summarys",
      //   component: () => import("@/views/stock/summarys"),
      //   name: "summarys",
      //   meta: {
      //     title: "商品收发汇总",
      //     routerId: "16",
      //   },
      // },
      {
        path: "cost_adjustment",
        component: () => import("@/views/stock/cost_adjustment"),
        name: "cost_adjustment",
        hidden: true,
        meta: {
          title: "成本调整",
          routerId: "17",
        },
      },
      {
        path: "carry_forward",
        component: () => import("@/views/stock/carry_forward"),
        name: "carry_forward",
        meta: {
          title: "期末结转",
          routerId: "18",
        },
      },
    ],
  },
  {
    path: "/staff",
    component: Layout,
    redirect: "/staff/account_number",
    alwaysShow: true,
    name: "staff",
    meta: {
      title: "部门与员工",
      icon: "el-icon-user",
    },
    children: [
      {
        path: "account_number",
        component: () => import("@/views/staff/account_number"),
        name: "account_number",
        meta: {
          title: "员工账号",
          routerId: "19",
        },
      },
      {
        path: "setting",
        component: () => import("@/views/staff/setting"),
        name: "setting",
        meta: {
          title: "部门设置",
          routerId: "20",
        },
      },
      {
        path: "jurisdiction",
        component: () => import("@/views/staff/jurisdiction"),
        name: "jurisdiction",
        meta: {
          title: "角色权限设置",
          routerId: "21",
        },
      },
      {
        path: "add_number",
        hidden: true,
        component: () => import("@/views/staff/add_number"),
        name: "add_number",
        meta: {
          title: "新增账号",
          routerId: "22",
        },
      },
    ],
  },
  {
    path: "/warehouse",
    component: Layout,
    redirect: "/warehouse/index",
    alwaysShow: true,
    name: "warehouse",
    meta: {
      title: "仓库管理",
      icon: "el-icon-house",
    },
    children: [
      {
        path: "index",
        component: () => import("@/views/warehouse/index"),
        name: "warehouseIndex",
        meta: {
          title: "仓库规划",
          routerId: "23",
        },
      },
    ],
  },
  {
    path: "/customer",
    component: Layout,
    redirect: "/customer/list",
    name: "customer",
    alwaysShow: true,
    meta: {
      title: "客户管理",
      icon: "el-icon-user-solid",
    },
    children: [
      {
        path: "list",
        name: "customerList",
        component: () => import("@/views/customer/list"),
        meta: {
          title: "客户列表",
          routerId: "24",
        },
      },
      {
        path: "label",
        name: "customerLabel",
        component: () => import("@/views/customer/label/index.vue"),
        meta: {
          title: "客户标签",
          routerId: "24-2",
        },
      },
      {
        path: "linkup_log/:user_id/:customer_name",
        props: true,
        name: "linkup_log",
        component: () => import("@/views/customer/linkup_log"),
        hidden: true,
        meta: {
          title: "沟通记录",
          routerId: "24-1",
        },
      },
      {
        path: "rank",
        name: "rank",
        component: () => import("@/views/customer/rank"),
        meta: {
          title: "客户级别",
          routerId: "25",
        },
      },
      // {
      //   path: "business_opportunity",
      //   name: "business_opportunity",
      //   component: () => import("@/views/customer/business_opportunity"),
      //   meta: {
      //     title: "商机管理",
      //     routerId: "26",
      //   },
      // },
      // {
      //   path: "feedback",
      //   name: "feedback",
      //   component: () => import("@/views/customer/feedback"),
      //   meta: {
      //     title: "客户反馈",
      //     routerId: "26",
      //   },
      // },
      {
        path: "updata",
        name: "customerupdata",
        component: () => import("@/views/customer/updata"),
        hidden: true,
        beforeEnter: (to, from, next) => {
          if (to.query.id) {
            to.meta.title = "编辑客户";
          } else {
            to.meta.title = "新增客户";
          }
          next();
        },
        meta: {
          title: "新增客户",
          routerId: "26",
        },
      },
      {
        path: "equitycard/index",
        name: "equitycard",
        component: () => import("@/views/customer/equitycard/index"),
        meta: {
          title: "权益卡",
          routerId: "26",
        },
      },
      {
        path: "equitycard/updataCard",
        name: "updataCard",
        component: () => import("@/views/customer/equitycard/updataCard"),
        hidden: true,
        beforeEnter: (to, from, next) => {
          if (to.query.id) {
            to.meta.title = "编辑权益卡";
          } else {
            to.meta.title = "新增权益卡";
          }
          next();
        },
        meta: {
          title: "新增权益卡",
          routerId: "26",
        },
      },
    ],
  },
  {
    path: "/finance",
    component: Layout,
    redirect: "/finance/receipt",
    alwaysShow: true,
    name: "finance",
    meta: {
      title: "财务管理",
      icon: "el-icon-money",
    },
    children: [
      {
        path: "receipt",
        component: () => import("@/views/finance/receipt"),
        name: "receipt",
        meta: {
          title: "收款单",
          routerId: "23",
        },
      },
      {
        path: "add_receipt",
        component: () => import("@/views/finance/add_receipt"),
        name: "add_receipt",
        hidden: true,
        meta: {
          title: "新增收款单",
          routerId: "9",
        },
      },
      {
        path: "add_receipt_new",
        component: () => import("@/views/finance/add_receipt_new"),
        name: "add_receipt_new",
        hidden: true,
        meta: {
          title: "新增收款单",
          routerId: "9",
        },
      },
      {
        path: "receiptinfo",
        component: () => import("@/views/finance/receiptinfo"),
        name: "receiptinfo",
        hidden: true,
        meta: {
          title: "收款单详情",
          routerId: "9",
        },
      },
      {
        path: "payment",
        component: () => import("@/views/finance/payment"),
        name: "payment",
        meta: {
          title: "付款单",
          routerId: "23",
        },
      },
      {
        path: "paymentinfo",
        component: () => import("@/views/finance/paymentinfo"),
        name: "paymentinfo",
        hidden: true,
        meta: {
          title: "付款单详情",
          routerId: "9",
        },
      },
      {
        path: "add_payment",
        component: () => import("@/views/finance/add_payment"),
        name: "add_payment",
        hidden: true,
        meta: {
          title: "新增付款单",
          routerId: "9",
        },
      },
      //   {
      //     path: "sure_receipt",
      //     component: () => import("@/views/finance/sure_receipt"),
      //     name: "sure_receipt",
      //     meta: {
      //       title: "收款确认",
      //       routerId: "23",
      //     },
      //   },
      // {
      //     path: "sure_refund",
      //     component: () => import("@/views/finance/sure_refund"),
      //     name: "sure_refund",
      //     meta: {
      //         title: "退款确认",
      //         routerId: "23",
      //     },
      // },
      // {
      //     path: "otherinout",
      //     component: () => import("@/views/finance/otherinout"),
      //     name: "otherinout",
      //     meta: {
      //         title: "其他收支",
      //         routerId: "23",
      //     },
      // },
      {
        path: "inout_info",
        component: () => import("@/views/finance/inout_info"),
        name: "inout_info",
        meta: {
          title: "收支明细",
          routerId: "23",
        },
      },
      {
        path: "inout_info_new",
        component: () => import("@/views/finance/inout_info_new"),
        name: "inout_info_new",
        meta: {
          title: "收支明细",
          routerId: "23",
        },
      },
      {
        path: "ordereceipt_total",
        component: () => import("@/views/finance/ordereceipt_total"),
        name: "ordereceipt_total",
        meta: {
          title: "订单收款统计",
          routerId: "23",
        },
      },
      {
        path: "addreceipt_client",
        component: () => import("@/views/finance/addreceipt_client"),
        name: "addreceipt_client",
        hidden: true,
        meta: {
          title: "按客户添加收款",
          routerId: "9",
        },
      },
      {
        path: "fundaccount",
        component: () => import("@/views/finance/fundaccount"),
        name: "fundaccount",
        // hidden: true,
        meta: {
          title: "资金账户",
          routerId: "9",
        },
      },
      {
        path: "fundaccount_import",
        component: () => import("@/views/finance/fundaccount_import"),
        name: "fundaccount_import",
        meta: {
          title: "导入资金账户",
          routerId: "4",
        },
        hidden: true,
      },
      {
        path: "fundinfo",
        component: () => import("@/views/finance/fundinfo"),
        name: "fundinfo",
        hidden: true,
        meta: {
          title: "资金明细",
          routerId: "9",
        },
      },
      {
        path: "payment_type",
        component: () => import("@/views/finance/components/payment_type"),
        name: "payment_type",
        hidden: true,
        meta: {
          title: "收款账户",
          routerId: "9",
        },
      },
      {
        path: "fundset",
        component: () => import("@/views/finance/fundset"),
        name: "fundset",
        hidden: true,
        meta: {
          title: "资金设置",
          routerId: "9",
        },
      },
      {
        path: "turnover_details",
        component: () => import("@/views/finance/turnover_details"),
        name: "turnover_details",
        meta: {
          title: "流水额明细",
          routerId: "23",
        },
      },
    ],
  },
  {
    path: "/purchase",
    component: Layout,
    redirect: "/purchase/supplier_management",
    alwaysShow: true,
    name: "purchase",
    meta: {
      title: "采购管理",
      icon: "el-icon-s-shop",
    },
    children: [
      {
        path: "supplier_management",
        component: () => import("@/views/purchase/supplier_management"),
        name: "supplier_management",
        meta: {
          title: "供应商管理",
          routerId: "19",
        },
      },
      {
        path: "supplier_inout_info",
        component: () => import("@/views/purchase/supplier_inout_info"),
        name: "supplier_inout_info",
        hidden: true,
        meta: {
          title: "供应商对账",
        },
      },
      {
        path: "account",
        component: () => import("@/views/purchase/account"),
        name: "account",
        meta: {
          title: "来往账",
          routerId: "19-1",
        },
      },
      {
        path: "add_supplier",
        component: () => import("@/views/purchase/components/add_supplier"),
        name: "add_supplier",
        hidden: true,
        meta: {
          title: "新增供应商",
          routerId: "19",
        },
      },
      {
        path: "edit_supplier",
        component: () => import("@/views/purchase/components/edit_supplier"),
        name: "edit_supplier",
        hidden: true,
        meta: {
          title: "修改供应商",
          routerId: "19",
        },
      },
      {
        path: "supplygoods",
        component: () => import("@/views/purchase/components/supplygoods"),
        name: "supplygoods",
        hidden: true,
        meta: {
          title: "供货商品",
          routerId: "19",
        },
      },
      {
        path: "capacity_purchase",
        component: () => import("@/views/purchase/capacity_purchase"),
        name: "capacity_purchase",
        hidden: true,
        meta: {
          title: "智能采购",
          routerId: "19",
        },
      },
      {
        path: "purchase_order",
        component: () => import("@/views/purchase/purchase_order"),
        name: "purchase_purchase_order",
        // hidden: true,
        meta: {
          title: "采购订单",
          routerId: "19",
        },
      },
      {
        path: "purchaseorder_info",
        component: () => import("@/views/purchase/components/purchaseorder_info"),
        name: "purchaseorder_info",
        hidden: true,
        meta: {
          title: "采购订单详情",
          routerId: "19",
        },
      },
      {
        path: "addpurchase_order",
        component: () => import("@/views/purchase/components/addpurchase_order"),
        name: "addpurchase_order",
        hidden: true,
        meta: {
          title: "新增采购订单",
          routerId: "19",
        },
      },
      {
        path: "editpurchase_order",
        component: () => import("@/views/purchase/components/editpurchase_order"),
        name: "editpurchase_order",
        hidden: true,
        meta: {
          title: "修改采购订单",
          routerId: "19",
        },
      },
      {
        path: "purchase_back",
        component: () => import("@/views/purchase/purchase_back"),
        name: "purchase_back",
        // hidden: true,
        meta: {
          title: "采购退货",
          routerId: "19",
        },
      },
      {
        path: "addpurchase_return",
        component: () => import("@/views/purchase/components/addpurchase_return"),
        name: "addpurchase_return",
        hidden: true,
        meta: {
          title: "新增采购退货单",
          routerId: "19",
        },
      },
      {
        path: "purchasereturn_info",
        component: () => import("@/views/purchase/components/purchasereturn_info"),
        name: "purchasereturn_info",
        hidden: true,
        meta: {
          title: "采购退货详情",
          routerId: "19",
        },
      },
      {
        path: "purchase_collect",
        component: () => import("@/views/purchase/purchase_collect"),
        name: "purchase_collect",
        // hidden: true,
        meta: {
          title: "采购汇总",
          routerId: "19",
        },
      },
      {
        path: "purchase_cate",
        component: () => import("@/views/purchase/purchase_cate"),
        name: "purchase_cate",
        // hidden: true,
        meta: {
          title: "采购成本分类",
          routerId: "19",
        },
      },
    ],
  },
  {
    path: "/logistics",
    component: Layout,
    alwaysShow: true,
    meta: {
      title: "物流管理",
      icon: "el-icon-box",
    },
    children: [
      {
        path: "/administration/carmanagement",
        component: () => import("@/views/order/administration/carmanagement"),
        name: "carmanagement",
        // hidden: true,
        meta: {
          title: "车辆管理",
          routerId: "9",
        },
      },
      {
        path: "/administration/car-melt/:id",
        component: () => import("@/views/order/administration/car-melt"),
        props: true,
        name: "carmelt",
        hidden: true,
        meta: {
          title: "查看车销",
          routerId: "9",
        },
      },
      {
        path: "",
        component: () => import("@/views/logistics/provide/index.vue"),
        name: "logistics",
        meta: {
          title: "物流商模块",
        },
      },
      {
        path: "/line",
        component: () => import("@/views/logistics/line/index.vue"),
        name: "line",
        meta: {
          title: "线路模块",
          routerId: "9",
        },
      },
      {
        path: "associated_customer",
        component: () => import("@/views/logistics/line/associated_customer.vue"),
        name: "associated_customer",
        hidden: true,
        meta: {
          title: "关联客户",
          routerId: "9-1",
        },
      },
      {
        path: "/distribution_task",
        component: () => import("@/views/logistics/distribution_task/index.vue"),
        name: "distribution_task",
        meta: {
          title: "配送任务",
        },
      },
    ],
  },
  {
    path: "/commission",
    component: Layout,
    alwaysShow: true,
    redirect: "commission/commission",
    meta: {
      title: "提成管理",
      icon: "el-icon-coin",
    },
    children: [
      {
        path: "scheme",
        component: () => import("@/views/commission/scheme"),
        name: "scheme",
        meta: {
          title: "提成方案",
          routerId: "9",
        },
      },
      {
        path: "scheme-add",
        component: () => import("@/views/commission/scheme/add"),
        name: "scheme-add",
        hidden: true,
        meta: {
          title: "新增提成方案",
          routerId: "9-1",
        },
      },
      {
        path: "record",
        component: () => import("@/views/commission/record"),
        name: "record",
        meta: {
          title: "提成记录",
          routerId: "66",
        },
      },
    ],
  },
  {
    path: "/marketing",
    component: Layout,
    alwaysShow: true,
    name: "marketing",
    redirect: "/marketing/spell-group",
    meta: {
      title: "营销管理",
      icon: "el-icon-money",
    },
    children: [
      {
        path: "spell-group",
        component: () => import("@/views/marketing/SpellGroup/index"),
        name: "spell-group",
        meta: {
          title: "阶梯拼团",
          routerId: "23",
        },
      },
      {
        path: "spell-group-add",
        component: () => import("@/views/marketing/SpellGroup/add"),
        name: "spell-group-add",
        hidden: true,
        meta: {
          title: "新增阶梯拼团",
          routerId: "24",
        },
      },
      {
        path: "money-off",
        component: () => import("@/views/marketing/MoneyOff/index"),
        name: "money-off",
        meta: {
          title: "满减/赠",
          routerId: "25",
        },
      },
      {
        path: "money-off-add",
        component: () => import("@/views/marketing/MoneyOff/add"),
        name: "money-off-add",
        hidden: true,
        meta: {
          title: "添加减/赠规则",
          routerId: "26",
        },
      },
      {
        path: "discount-coupon",
        component: () => import("@/views/marketing/DiscountCoupon/index"),
        name: "discount-coupon",
        meta: {
          title: "优惠券",
          routerId: "28",
        },
      },
      {
        path: "discount-coupon-add",
        component: () => import("@/views/marketing/DiscountCoupon/add"),
        name: "discount-coupon-add",
        hidden: true,
        meta: {
          title: "添加满减券",
          routerId: "29",
        },
      },
      {
        path: "top-up",
        component: () => import("@/views/marketing/TopUp/index"),
        name: "top-up",
        meta: {
          title: "营销充值",
          routerId: "30",
        },
      },
      {
        path: "top-up-add",
        component: () => import("@/views/marketing/TopUp/add"),
        name: "top-up-add",
        hidden: true,
        meta: {
          title: "充值",
          routerId: "31",
        },
      },
      {
        path: "big-wheel",
        component: () => import("@/views/marketing/BigWheel/index"),
        name: "big-wheel",
        meta: {
          title: "大转盘",
          routerId: "32",
        },
      },
      {
        path: "big-wheel-add",
        component: () => import("@/views/marketing/BigWheel/add"),
        name: "big-wheel-add",
        hidden: true,
        meta: {
          title: "新增大转盘",
          routerId: "33",
        },
      },
      {
        path: "addup-gift",
        component: () => import("@/views/marketing/AddUpGift/index"),
        name: "addup-gift",
        meta: {
          title: "累计有礼",
          routerId: "34",
        },
      },
      {
        path: "addup-gift-add",
        component: () => import("@/views/marketing/AddUpGift/add"),
        name: "addup-gift-add",
        hidden: true,
        meta: {
          title: "添加累计有礼",
          routerId: "35",
        },
      },
      {
        path: "qantity-discounts",
        component: () => import("@/views/marketing/QantityDiscounts/index"),
        name: "qantity-discounts",
        meta: {
          title: "量大从优",
          routerId: "36",
        },
      },

      {
        path: "qantity-discounts-add",
        component: () => import("@/views/marketing/QantityDiscounts/add"),
        name: "qantity-discounts-add",
        hidden: true,
        meta: {
          title: "添加量大从优",
          routerId: "37",
        },
      },
      {
        path: "goods-promotion",
        component: () => import("@/views/marketing/GoodsPromotion/index"),
        name: "goods-promotion",
        meta: {
          title: "商品促销",
          routerId: "38",
        },
      },
      {
        path: "goods-promotion-add",
        component: () => import("@/views/marketing/GoodsPromotion/add"),
        name: "goods-promotion-add",
        hidden: true,
        meta: {
          title: "新增商品促销",
          routerId: "37",
        },
      },
    ],
  },
  // {
  //   path: "/store-build",
  //   component: () => import("@/views/StoreBuild/index"),
  //   name: "store-build",
  //   meta: {
  //     title: "店铺装修",
  //     routerId: "42",
  //   },
  // },
  {
    path: "/store-build",
    component: () => import("@/views/StoreBuild-re/index"),
    name: "store-build",
    meta: {
      title: "店铺装修",
      routerId: "42",
    },
  },
  {
    path: "/program",
    component: Layout,
    alwaysShow: true,
    name: "program",
    redirect: "/program/banner",
    meta: {
      title: "小程序管理",
      icon: "el-icon-s-tools",
    },
    children: [
      {
        path: "decorate",
        component: () => import("@/views/program/Decorate/index"),
        name: "decorate",
        meta: {
          title: "店铺装修",
          routerId: "42",
        },
      },
      // {
      //     path: "decorate-add",
      //     component: () => import("@/views/program/Decorate/add"),
      //     name: "decorate-add",
      //     meta: {
      //         title: "新增页面",
      //         routerId: "43",
      //     },
      // },
      {
        path: "system-cfg",
        component: () => import("@/views/program/SystemCfg"),
        name: "system-cfg",
        meta: {
          title: "系统配置",
          routerId: "41",
        },
      },
      {
        path: "banner",
        component: () => import("@/views/program/Banner/index"),
        name: "banner",
        meta: {
          title: "轮播图管理",
          routerId: "38",
        },
      },
      {
        path: "signin",
        component: () => import("@/views/program/Signin/index"),
        name: "signin",
        meta: {
          title: "签到管理",
          routerId: "39",
        },
      },
      {
        path: "about-us",
        component: () => import("@/views/program/AboutUs/index"),
        name: "about-us",
        meta: {
          title: "富文本管理",
          routerId: "40",
        },
      },
    ],
  },
  {
    path: "/system",
    component: Layout,
    alwaysShow: true,
    name: "sytem",
    redirect: "/system/account",
    meta: {
      title: "系统管理",
      icon: "el-icon-s-tools",
    },
    children: [
      {
        path: "account",
        component: () => import("@/views/system/account/index"),
        name: "sys-account",
        meta: {
          title: "账号管理",
          routerId: "41",
        },
      },
      {
        path: "permission",
        component: () => import("@/views/system/permission/index"),
        name: "sys-permission",
        meta: {
          title: "权限管理",
        },
      },
      {
        path: "log",
        component: () => import("@/views/system/log/index"),
        name: "sys-log",
        meta: {
          title: "日志管理",
        },
      },
    ],
  },
  {
    path: "/fix",
    component: Layout,
    name: "维修管理",
    redirect: "/fix/fix-list",
    meta: {
      title: "维修管理",
      icon: "el-icon-s-tools",
    },
    children: [
      {
        path: "fix-list",
        component: () => import("@/views/fix/fix-order/index"),
        name: "维修列表",
        meta: {
          title: "维修列表",
        },
      },
      {
        path: "add-fix",
        component: () => import("@/views/fix/fix-order/add"),
        name: "add-fix",
        meta: {
          title: "新增维修单",
        },
        beforeEnter: (to, from, next) => {
          if (to.query.id) {
            to.meta.title = "编辑维修单";
            if (to.query.preview) {
              to.meta.title = "查看维修单";
            }
          } else {
            to.meta.title = "新增维修单";
          }
          next();
        },
      },
      {
        path: "fix-config-list",
        component: () => import("@/views/fix/fix-config/index"),
        name: "config-list",
        meta: {
          title: "维修配置",
        },
      },
      {
        path: "fix-add-config",
        component: () => import("@/views/fix/fix-config/add"),
        name: "add-config",
        meta: {
          title: "新增维修配置",
        },
        beforeEnter: (to, from, next) => {
          if (to.query.id) {
            to.meta.title = "编辑维修配置";
          } else {
            to.meta.title = "新增维修配置";
          }
          next();
        },
      },
    ],
  },
];

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: [...asyncRoutes, ...constantRoutes],
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // 重置 router
}

export default router;
