<template>
  <div>
    <el-table :key="key" ref="table" :cell-style="{ border: 'none' }" :data="list" :header-cell-style="headerStyle" :height="height + 'px'" :max-height="maxHeight" :size="size" border row-key="id" style="width: 100%" v-bind="$attrs" :cell-class-name="cellClassMame" @selection-change="handleSelectionChange" @sort-change="handleSortChange">
      <!--用template包裹防止表头顺序错乱-->
      <template v-for="(item, index) in columnsData">
        <!--选择框表头-->
        <el-table-column v-if="item.headerType == 'select'" :key="item.key" :fixed="item.fixed" :reserve-selection="true" :selectable="selectable" type="selection" width="50"></el-table-column>
        <!--自定义表头项-->
        <el-table-column v-if="item.headerType == 'custom'" :key="item.key" :fixed="item.fixed" :min-width="item.width">
          <template slot="header" slot-scope="scope">
            <slot name="tableHeader"></slot>
          </template>
          <template slot-scope="scope">
            <!--默认渲染数据-->
            <div v-if="!item.slotName">
              {{ scope.row[item.prop] }}
            </div>
            <!--需要特殊处理的数据插槽-->
            <slot v-if="item.type == 'slot'" :data="scope.row[item.prop]" :name="item.slotName"></slot>
            <!--操作按钮插槽-->
            <slot v-if="item.type == 'button'" :data="scope.row" name="operation"></slot>
          </template>
        </el-table-column>
        <!--自定义展示字段表头项-->
        <el-table-column v-if="item.headerType == 'operation'" :key="item.key" :fixed="item.fixed" :min-width="item.width">
          <template slot="header" slot-scope="scope">
            <el-button icon="el-icon-s-operation" size="small" style="font-size: 20px; padding: 0" type="text" @click="openDrwe"></el-button>
          </template>
          <template slot-scope="scope">
            <!--          默认渲染数据-->
            <div v-if="!item.slotName">
              {{ scope.row[item.prop] }}
            </div>
            <!--          需要特殊处理的数据插槽-->
            <slot v-if="item.type == 'slot'" :data="scope.row[item.prop]" :name="item.slotName" :row="scope.row"></slot>
            <!--          操作按钮插槽-->
            <slot v-if="item.type == 'button'" :data="scope.row" name="operation"></slot>
          </template>
        </el-table-column>
        <!--        普通表头-->
        <el-table-column v-if="!item.headerType" :key="item.key" :fixed="item.fixed" :label="item.label" :min-width="item.width" :prop="item.prop" :sortable="item.sort ? 'custom' : false">
          <template slot-scope="scope">
            <!--          默认渲染数据-->
            <div v-if="!item.slotName">
              <div v-if="item.click">
                <el-tooltip :content="handleVal(scope.row, item.prop)" effect="dark" placement="top-start">
                  <el-link @click="item.click(scope)">
                    {{ handleVal(scope.row, item.prop) }}
                  </el-link>
                </el-tooltip>
              </div>
              <div v-else>
                {{ handleVal(scope.row, item.prop) }}
              </div>
              <!-- {{ scope.row[item.prop] }}{{ item.prop }} -->
            </div>
            <!--          默认渲染数据-->
            <div v-if="item.type == 'idx'" class="flex">
              {{ scope.$index + 1 }}
              <i v-if="draggable" class="handle el-icon-s-grid"></i>
            </div>
            <!--          需要特殊处理的数据插槽-->
            <slot v-if="item.type == 'slot'" :data="scope.row" :item="item" :keyName="item.key" :name="item.slotName" :row="scope.row"></slot>
            <!--          操作按钮插槽-->
            <slot v-if="item.type == 'button'" :data="scope.row" name="operation"></slot>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <el-drawer :append-to-body="true" :visible.sync="drawer" :with-header="false" size="250px">
      <header class="el-drawer__header">
        <span>选择显示字段</span>
        <button class="el-drawer__close-btn" @click="drawer = false">
          <i class="el-dialog__close el-icon el-icon-close"></i>
        </button>
      </header>
      <div class="check-box">
        <div style="height: 65vh; overflow-y: auto">
          <!-- <draggable
                        :options="{ handle: '.handle' }"
                        v-model="tableHeadList"
                    > -->
          <div v-for="(item, index) in tableHeadList" :key="item.key" class="check-item">
            <div v-if="item.label && item.type !== 'idx'" style="padding: 10px 20px">
              <div class="flex">
                <el-checkbox v-model="item.check" :disabled="!item.prop" style="width: 100%">
                  {{ item.label }}
                </el-checkbox>
                <i v-if="item.type !== 'idx'" class="handle el-icon-top" @click="up(index)"></i>
                <i v-if="item.type !== 'idx'" class="handle el-icon-bottom" @click="down(index)"></i>
              </div>
            </div>
          </div>
          <!-- </draggable> -->
        </div>
        <el-button-group style="margin-left: 60px; margin-top: 30px">
          <el-button @click="drawer = false">取消</el-button>
          <el-button type="primary" @click="setTableHead">确定</el-button>
        </el-button-group>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import draggable from "@/components/Draggable";
import Sortable from "sortablejs";
import { findIndex, get, sortBy } from "lodash";
import { addTableRule, getTableRule } from "@/api/common";

export default {
  components: {
    draggable,
  },
  props: {
    /**
     * 表头配置数组
     * 示例
     * [
     * {
     *    label: '名称', 列名,必要属性
            width: '300', 列宽,必要属性
            prop: 'name', 渲染的属性名,非必填
            type: 'slot', 列类型,非必填
            slotName: 'name',  插槽名称,非必填
            headerType:'custom' 表头类型,非必填
            keu:'唯一值'，使用自定义展示字段功能时必填。防止表头顺序错乱
            fixed:'right',是否固定列，参数同elementui
            sort:true,是否自定义排序
     * }
     * ]
     *
     * type:列类型,非必填
     * 值为: slot  button idx
     * type=slot:type值为slot表示此列为自定义渲染项,即自行提供渲染模板,可以自定义渲染样式.组件只提供当前属性值,不提供当前完整对象
     * 当type=slot时slotName必填,值为当前列对应的prop
     * 模板语法
     * <!--      自定义渲染示例：v-slot:插槽名称,即属性名="{data}"  data为此属性的值-->
     * <template v-slot:name="{data}">
     {{data}}
     </template>
     *
     * type=button:type值为button表示为操作列,此时组件提供当前行完整对象数据,一般用于写操作按钮.需自行提供渲染模板
     * 模板语法
     * <!--      按钮栏渲染规则:v-slot:operation="{data}",data为此行所有数据。-->
     <template v-slot:operation="{data}">
     <div class="table_cz_group">
     <div class="table_cz" @click="editWare(data)">
     修改
     </div>
     <div class="table_cz" @click="delWare(data)">
     删除
     </div>
     </div>
     </template>
     *
     * headerType:表头类型,非必填
     * 值为:select custom operation
     * headerType=select:headerType值为select时开启列表选择功能,需提供selected方法接收勾选中的数据.
     * 此时列对象内可不包含任何数据,只保留headerType:'select',即可
     * 示例: {
            headerType:'select',
          },
     *
     * headerType=custom:headerType值为custom时开启自定义表头,需自行提供表头模板.可自行定义表头样式和功能
     * 模板语法
     *   <template v-slot:tableHeader>
     <el-input
     size="mini"
     placeholder="输入关键字搜索"/>
     </template>
     *
     * headerType=operation:headerType值为operation时开启自定义展示表格数据功能.此时提供自定义选择展示哪些表头功能.
     *使用此功能需要给每个列配置项加上key属性，唯一值，防止表头顺序错乱
     *
     * 注:type和headerType并无关联,headerType是控制表头.type是控制表格内容.
     * 当type=slot时slotName必填为当前列所要渲染的属性名.除此之外,slotName无其他作用
     */
    columns: {
      type: Array,
      default: [],
    },
    height: {
      type: Number,
      default: 500,
    },
    //表格最大高度
    maxHeight: {
      type: Number,
      default: 800,
    },
    //表头样式,格式同elementui
    headerStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
    //表数据
    list: {
      type: Array,
      default: [],
    },
    // 大小
    size: {
      type: String,
      default: "small",
    },
    //是否禁用当前行不可勾选
    selectable: {
      type: Function,
      default: () => {},
    },
    ruleName: {
      //用户对表格排序规则，字段展示
      type: String,
    },
    draggable: {
      type: Boolean,
      default: false, // 是否开启index拖拽
    },
    cellClassMame: {
      type: Function,
      default: () => {},
    },
  },
  name: "wtable",
  data() {
    return {
      key: 0, //用于更新el-table 不更新
      drawer: false,
      columnsData: [...this.columns.filter((item) => item.label || item.headerType == "select")],
      tableHeadList: [],
      selectedCheckList: [], // 选中需要展示的表格数据
    };
  },
  watch: {
    key() {
      this.draggable && this.initSortable();
    },
    columns() {
      this.filterCloumns();
      this.$refs.table.doLayout();
    },
    list(){
      this.$refs.table.doLayout();
    }
  },
  computed: {
    handleVal() {
      return (row, prop) => {
        return get(row, prop);
      };
    },
  },
  async created() {
    if (this.ruleName) {
      await this.getTableRule();
      this.filterCloumns();
    }
  },
  methods: {
    up(index) {
      if (index > 0) {
        const current = JSON.parse(JSON.stringify(this.tableHeadList[index]));
        const prev = JSON.parse(JSON.stringify(this.tableHeadList[index - 1]));
        this.$set(this.tableHeadList, index, prev);
        this.$set(this.tableHeadList, index - 1, current);
      }
    },
    down(index) {
      if (index < this.tableHeadList.length - 1) {
        const current = JSON.parse(JSON.stringify(this.tableHeadList[index]));
        const next = JSON.parse(JSON.stringify(this.tableHeadList[index + 1]));
        this.$set(this.tableHeadList, index, next);
        this.$set(this.tableHeadList, index + 1, current);
      }
    },
    initSortable() {
      this.$nextTick(() => {
        const el = document.querySelector(".el-table__body >tbody");
        // el.style.cursor = "pointer";
        const _this = this;
        this.sortable = Sortable.create(el, {
          animation: 300,
          handle: ".handle",
          dragClass: "dragClass", //设置拖拽样式类名
          ghostClass: "ghostClass", //设置拖拽停靠样式类名
          chosenClass: "chosenClass", //设置选中样式类名
          onUpdate(e) {
            _this.onUpdate(e);
          },
        });
      });
    },
    onUpdate(e) {
      const list = this.list.slice(0);
      const item = list.splice(e.oldIndex, 1)[0];
      list.splice(e.newIndex, 0, item);
      this.$emit("draggableChange", list);
      this.$emit("update:list", list); //对外层需要进行重排
    },
    async getTableRule() {
      const {
        data,
        data: { list_data },
      } = await getTableRule(this.ruleName);
      if (data.length == 0 || list_data.length === 0) {
        this.selectedCheckList = this.columnsData.map((item) => {
          return {
            check: true,
            prop: item.prop,
          };
        });
      } else {
        this.selectedCheckList = list_data || [];
      }
    },
    filterCloumns() {
      // 根据 selectedCheckList 进行表头排序
      const columns = sortBy(this.columns, (x) => {
        const index = findIndex(this.selectedCheckList, (y) => x.prop === y.prop);
        return index >= 0 ? index : -1;
      });

      this.tableHeadList = columns.map((item) => {
        //勾选默认选中
        this.selectedCheckList.forEach((selection) => {
          if (item.prop === selection.prop) {
            this.$set(item, "check", selection.check);
          }
        });
        return item;
      });
      this.columnsData = columns.filter((item) => {
        const hasMatchingProp = this.selectedCheckList.some((selection) => selection.prop === item.prop && selection.check);
        const isOperationHeader = item.headerType === "operation";
        const isSelectHeader = item.headerType === "select";
        const isIdxType = item.type === "idx";
        const isNew = this.selectedCheckList.every((selection) => selection.prop !== item.prop);
        return hasMatchingProp || isOperationHeader || isSelectHeader || isIdxType || isNew;
      });

      this.key++; //更新表格
    },
    async setTableHead() {
      //设置表格头
      const list_data = this.tableHeadList
        .filter((item) => item.prop)
        .map((item) => ({
          prop: item.prop,
          check: item.check,
        }));
      await addTableRule(this.ruleName, list_data);
      await this.getTableRule();
      this.filterCloumns();
      this.drawer = false;
    },
    openDrwe() {
      this.drawer = true;
    },
    handleSortChange(e) {
      this.$emit("sortChange", e);
    },
    handleSelectionChange(e) {
      this.$emit("selected", e);
      this.$emit("handleSelectionChange", e);
    },

    clearSelection() {
      this.$refs.table.clearSelection();
    },
    getRef() {
      return this.$refs.table;
    },
  },
};
</script>

<style lang="scss" scoped>
.check-box {
  position: relative;
  height: 80%;
  overflow-y: auto;

  .check-item {
    &:hover {
      background-color: #f5f3f2;
      cursor: pointer;
    }
  }
}

.handle {
  margin-left: 10px;
  cursor: pointer;
  font-size: 18px;
}

.handle:hover {
  color: #409eff;
}
</style>
