import { BASE_URL } from "@/settings";

const getters = {
  sidebar: (state) => state.app.sidebar,
  device: (state) => state.app.device,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  routers: (state) => state.user.routers,
  store_token: (state) => state.user.storeToken,
  storeInfo: (state) => state.user.storeInfo,
  pagePath: (state) => state.pageInfo.pagePath,
  pageParam: (state) => state.pageInfo.pageParam,
  currentPage: (state) => state.pageInfo.currentPage,
  finance_auth: (state) => state.user.storeInfo?.account?.detail?.finance_auth == 1,
  img_base_url: () => BASE_URL,
  // img_base_url: () => "http://192.168.101.61:13007/",
  base_url: () => BASE_URL,
  order_status: () => {
    return {
      0: "全部状态",
      1: "待订单审核",
      2: "待出库审核",
      3: "已收货",
      4: "已完成",
      5: "已作废",
      6: "待收货",
    };
  },
  delivery_status: () => {
    return {
      0: "全部",
      1: "未出库",
      2: "部分出库",
      3: "已出库",
    };
  },
  ship_status: () => {
    return {
      0: "全部",
      1: "未发货",
      2: "已发货操作等待司机确认",
      3: "已确认上车开始送货",
      4: "已收货",
    };
  },
  pay_status: () => {
    return {
      0: "全部",
      1: "未收款",
      2: "收款待审核",
      3: "部分收款",
      4: "已收款",
    };
  },
  pay_type: () => {
    // 1现金2银行卡3奖励基金账户4现金账户5退货账户6保证金账户7来往帐8微信支付9支付宝支付10其他
    return {
      0: "全部付款账户",
      1: "现金",
      2: "银行卡",
      3: "奖励基金账户",
      4: "现金账户",
      5: "退货账户",
      6: "保证金账户",
      7: "来往帐",
      8: "微信支付",
      9: "支付宝支付",
      10: "其他",
    };
    // return {
    //   0: "全部付款账户",
    //   1: "微信支付",
    //   2: "奖励基金余额",
    //   3: "现金账户余额",
    //   4: "退货账户余额",
    //   5: "线下支付",
    //   6: "银行转账",
    // };
  },
  store_pay_type: () => {
    // 1现金2银行卡3奖励基金账户4现金账户5退货账户6保证金账户7来往帐8微信支付9支付宝支付10其他',
    return {
      1: "现金",
      2: "银行卡",
      3: "奖励基金账户",
      4: "现金账户",
      5: "退货账户",
      6: "保证金账户",
      7: "来往帐",
      8: "微信支付",
      9: "支付宝支付",
      10: "其他",
    };
  },
  receipt_type: () => {
    return {
      0: "全部付款账户",
      1: "现金",
      2: "银行卡",
      3: "奖励基金账户",
      4: "现金账户",
      5: "退货账户",
      6: "保证金账户",
      7: "来往帐",
      8: "微信支付",
      9: "支付宝支付",
      10: "其他",
    };
    // return {
    //   0: "全部收款账户",
    //   1: "微信支付",
    //   2: "奖励基金账户",
    //   3: "现金账户",
    //   4: "退货账户",
    //   5: "线下现金账户",
    //   6: "银行转账",
    //   7: "现金",
    // };
  },
  purchase_order_type: () => {
    //1采购单欠款2采购单付款3供应商来往帐操作4系统给供应商付款5供应商退款给系统6采购退款单下单7.客户下单8客户付款9增加客户欠款10减少客户欠款11初始化用户资金12初始化供应商资金13系统付款给客户
    return {
      1: {
        label: "采购单下单",
        type: "danger",
        value:1
      },
      2: {
        label: "采购单付款",
        type: "success",
        value:2
      },
      3: {
        label: "来往帐操作",
        type: "success",
        value:3
      },
      4: {
        label: "系统付款",
        type: "success",
        value:4
      },
      5: {
        label: "供应商退款给系统",
        type: "danger",
        value:5
      },
      6: {
        label: "采购退款单下单",
        type: "success",
        value:6
      },
      7: {
        label: "客户下单",
        type: "success",
        value:7
      },
      8: {
        label: "客户付款",
        type: "danger",
        value:8
      },
      9: {
        label: "增加客户欠款",
        type: "success",
        value:9
      },
      10: {
        label: "减少客户欠款",
        type: "danger",
        value:10
      },
      11: {
        label: "初始化用户资金",
        type: "success",
        value:11
      },
      12: {
        label: "初始化供应商资金",
        type: "success",
        value:12
      },
      13: {
        label: "系统付款给客户",
        type: "danger",
        value:13
      },
    };
  },
  pay_digest: () => {
    // 1现金充值2银行卡充值3订单付款4订单退款5采购付款6采购退款7来往帐结算8转账转出9转账转入10拉新提成11销售饭点12红冲13佣金提现14其他15扣款16历史欠款增加17历史欠款减少
    return {
      1: "现金充值",
      2: "银行卡充值",
      3: "订单付款",
      4: "订单退款",
      5: "采购付款",
      6: "采购退款",
      7: "来往帐结算",
      8: "转账转出",
      9: "转账转入",
      10: "拉新提成",
      11: "销售饭点",
      12: "红冲",
      13: "佣金提现",
      14: "其他",
      15: "扣款",
      16: "历史欠款增加",
      17: "历史欠款减少",
    };
  },
  fix_flow_status: () => {
    // 1待检测2等待客户确认3维修中4已交付
    return {
      1: "待检测",
      2: "等待客户确认",
      3: "维修中",
      4: "已交付",
    };
  },
  fix_status: () => {
    // 1执行中2结束3意外终止
    return {
      1: "执行中",
      2: "结束",
      3: "意外终止",
    };
  },

  ship_status_list: () => {
    return {
      0: { value: 0, label: "全部" },
      1: { value: 1, label: "未分配" },
      2: { value: 2, label: "货物运输中/己分配" },
      3: { value: 3, label: "货物到达物流站" },
      4: { value: 4, label: "货物已送达" },
    };
  },
  ship_type: () => {
    //1普通 2电子面单发货 3线路发货 4自提发货
    return {
      1: "普通",
      2: "电子面单发货",
      3: "线路发货",
      4: "自提发货",
    };
  },
  account_type() {
    //1付费号 2体验号
    return {
      1: { value: 1, label: "体验号", type: "warning" },
      2: { value: 2, label: "付费号", type: "success" },
    };
  },
  ware_in_type(){
    // 入库类型1采购入库2销售退货3调拨入库4其它入库5盘盈6库存调整  7车销退回,
    return {
      1: { value: 1, label: "采购入库", type: "success" },
      2: { value: 2, label: "销售退货", type: "warning" },
      3: { value: 3, label: "调拨入库", type: "info" },
      4: { value: 4, label: "其它入库", type: "danger" },
      5: { value: 5, label: "盘盈", type: "success" },
      6: { value: 6, label: "库存调整", type: "warning" },
      7: { value: 7, label: "车销退回", type: "info" },
    }
  },
  ware_out_type(){
    //出库类型 1销售出库 2调拨出库 3采购退货 4其他出库 5盘亏6库存调整  7车销出库 8维修出库
    return {
      1: { value: 1, label: "销售出库", type: "success" },
      2: { value: 2, label: "调拨出库", type: "warning" },
      3: { value: 3, label: "采购退货", type: "info" },
      4: { value: 4, label: "其他出库", type: "danger" },
      5: { value: 5, label: "盘亏", type: "success" },
      6: { value: 6, label: "库存调整", type: "warning" },
      7: { value: 7, label: "车销出库", type: "info" },
      8: { value: 8, label: "维修出库", type: "danger" },
    }
  }
};
export default getters;
