import axios from "axios";
import store from "@/store";
import { getStoreToken, getToken } from "@/utils/auth";
import defaultSettings from "@/settings";
import router from "@/router";

// 创建axios实例
const service = axios.create({
  baseURL: process.env.NODE_ENV === "development" ? "/api" : defaultSettings.BASE_API, // url = base url + request url
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // 发送 cookies 当 cross-domain requests（跨域）的时候，如果前端配置为true，后段设置Access-Control-Allow-Origin不能为 " * ",必须是你的源地址
  timeout: 300000, // 设置请求超时的时间
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    //过滤post请求下的无效参数
    if (config.method == "post") {
      let data = { ...config.data };
      if (Object.keys(data).length > 0) {
        for (let key in data) {
          if (data[key] === "" || data[key] === null || data[key] === undefined) {
            delete data[key];
          }
        }
        config.data = data;
      }
    }
    // do something before request is sent
    if (store.getters.token) {
      // 让每个请求携带token--['S-Token']为自定义key 请根据实际情况自行修改
      config.headers["S-Token"] = getToken();
    }
    if (store.getters.store_token) {
      //如果获取到了商店token，则每个请求带上商店token
      config.headers["X-Token"] = getStoreToken();
    }
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

// respone拦截器
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const contentType = response.headers["content-type"];
    if (contentType == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      return response;
    }
    const res = response.data;
    // console.log(response)
    if (res.code !== 1) {
      ELEMENT.Message({
        message: res.message || "Error",
        type: "error",
        duration: 5 * 1000,
      });

      // -1: 非法的 Token; 50012: 其他客户端登录了; -2: Token 过期了;
      if (res.code === 0 && res.message == "商家登陆参数缺失") {
        store.dispatch("user/resetToken").then(() => {
          router.push("/login");
        });
      }
      if (res.code === -1 || res.code === 50012 || res.code === -2) {
        // 重新登录
        ELEMENT.MessageBox.confirm("登录状态已过期，您可以继续留在该页面，或者重新登录", "系统提示", {
          confirmButtonText: "重新登录",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          store.dispatch("user/resetToken").then(() => {
            location.reload(); // 为了重新实例化vue-router对象 避免bug
          });
        });
      }
      ELEMENT.Loading.service().close();
      return Promise.reject(res);
    } else {
      return res;
    }
  },
  (error) => {
    if (error.toString().indexOf("Error: timeout") !== -1) {
      ELEMENT.Message({
        message: "网络请求超时",
        type: "error",
        duration: 5 * 1000,
      });
    } else if (error.toString() == "Cancel: cancel") {
      ELEMENT.Message({
        message: "成功",
        type: "success",
      });
    } else {
      ELEMENT.Message({
        message: error.message,
        type: "error",
        duration: 5 * 1000,
      });
      ELEMENT.Loading.service().close();
    }
    return Promise.reject(error);
  }
);

export default service;
