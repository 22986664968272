import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import app from './modules/app'
import settings from './modules/settings'
import user from './modules/user'
import pageInfo from './modules/page_info'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        app,
        settings,
        user,
        pageInfo,
    },
    getters,
    plugins:[
        createPersistedState({
            key: 'vuex',
        })
    ]
})

export default store
