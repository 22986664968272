/**
 * 隐藏手机号码
 * @param val {Number, String} 转换的字符串对象
 * @param retain {Number} 保留位数
 * @return {String}
 */
export function fmoney(s) {
  let n = 2;
  /*
   * 参数说明：
   * s：要格式化的数字
   * n：保留几位小数
   * */
  n = n > 0 && n <= 20 ? n : 2;
  s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
  var l = s.split(".")[0].split("").reverse(),
    r = s.split(".")[1];
  let t = "";
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
  }
  return t.split("").reverse().join("") + "." + r;
}

/**
 * 日期格式 年月日
 */
export function date(timestamp) {
  if (!timestamp) return "";
  var date = "";
  var time = parseInt(timestamp);
  if (timestamp.toString().length == 10) {
    date = new Date(time * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  } else {
    date = new Date(time);
  }
  var Y = date.getFullYear() + "-";
  var M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  var h =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  var m =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return Y + M + D;
}

/**
 * 日期格式 年月日时分秒
 */
export function time(timestamp) {
  if (!timestamp) return "";
  var date = "";
  var time = parseInt(timestamp);
  if (timestamp.toString().length == 10) {
    date = new Date(time * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  } else {
    date = new Date(time);
  }
  var Y = date.getFullYear() + "-";
  var M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  var h =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  var m =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
}

/**
 * 处理商品单位
 */
export function filterMetering(metering) {
  let reslut = "";
  reslut = metering[0].value;
  reslut += metering[1]
    ? ` / ${metering[1].value}（${metering[1].rate}${metering[0].value}）`
    : "";
  reslut += metering[2]
    ? ` / ${metering[2].value}（${metering[2].rate}${metering[0].value}）`
    : "";
  return reslut;
}

/**
 * 计算单位
 */
export function calulerMetering(metering) {
  let nums = metering[metering.length - 2];
  let str = "";

  metering.forEach((element) => {
    if (typeof element === "object") {
      const result = Math.floor(nums / element.rate);
      nums = nums % element.rate;
      if (nums) {
        str += `${result}${element.value}`;
      }
    }
  });
  return str;
}
