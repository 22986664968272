import router from "./router";
// import { Message } from 'element-ui'
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { getToken } from "@/utils/auth"; // get token from cookie
import getPageTitle from "@/utils/get-page-title";

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = ["/login", "/404", "/selectStore", "/", "/system/permission"]; // 路由白名单 无需登录
router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();
  // 设置网页标题
  document.title = getPageTitle(to.meta.title);
  // determine whether the user has logged in
  const hasToken = getToken();
  if (hasToken) {
    if (to.path === "/login") {
      // 如果已登录且跳转的页面是登录页，重定向到选择店铺页面
      next({ path: "/selectStore" });
      NProgress.done();
    }
    next()
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // 处于登录白名单的路由，直接跳转
      next();
    } else {
      /**
       * 其他没有访问权限的页面将被重定向到登录页面
       * 登录成功之后将重定向到之前想跳转的页面
       */
      // next(`/login?redirect=${to.path}`)
      next(`/login`);
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
