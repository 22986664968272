<template>
    <div class="flex upload-image" style="gap:10px;align-items:flex-start;">
        <div class="image-list flex" style="gap:10px;flex-wrap: wrap;" v-if="showFileList == false">
            <div v-for="(item, index) in value" :key="'preview-' + item.url">
                <div class="image">
                    <div class="mask flex jus-c ali-c">
                        <i style="cursor:pointer;color:white;font-size: 20px;" class="el-icon-delete"
                            @click="fileList.splice(index, 1); emitName()"></i>
                    </div>
                    <el-image :preview-src-list="[item.url]" :src="item.url"
                        style="height: 146px;width:146px;border-radius: 6px;"></el-image>
                </div>
                <el-popover placement="top" width="240" v-model="item.visible">
                    <div class="flex">
                        <el-input placeholder="请输入修改名称" size="mini" v-model="item.copyname"></el-input>
                        <el-button class="ml-10" type="primary" size="mini"
                            @click="item.name = item.copyname; item.visible = false">确定</el-button>
                    </div>
                    <p slot="reference" style="text-align: center;height: 20px;line-height:20px;margin-bottom: 0;">
                        <el-link>{{item.name }}</el-link>
                    </p>
                </el-popover>
            </div>
        </div>
        <el-upload :action="action" :headers="headers" :multiple="multiple" :with-credentials="false" :data="data"
            :show-file-list="showFileList" :drag="false" accept=".jpg,.png" :on-preview="onPreview" :on-remove="onRemove"
            :on-success="onSuccess" :before-upload="beforeUpload" :on-exceed="onExceed" list-type="picture-card"
            :auto-upload="true" :file-list="fileList" :disabled="disabled" :limit="limit">
            <div slot="default">
                <i class="el-icon-plus" ref="uploadBtn"></i>
                <p></p>
            </div>
        </el-upload>
        <el-dialog :visible.sync="previewShow" title="预览图片" append-to-body>
            <img width="100%" :src="previewUrl" alt="" />
        </el-dialog>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    name: "el-upload-image",
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: true
        },
        limit: {
            type: Number,
            default: 1
        },
        value: {
            type: Array,
            default: () => []
        },
        listType: {
            type: String,
            default: 'picture-card'
        },
        showFileList: {
            type: Boolean,
            default: true
        }
    },
    model: {
        prop: "value",
        event: "change"
    },
    watch: {
        value() {
            if (this.fileList.length !== this.value.length) {
                this.fileList = this.value;
                return;
            }
            if (this.fileList.length === this.value.length) {
                const result = this.fileList.some((item, index) => {
                    return item.url !== this.value[index].url;
                });
                if (result) {
                    this.fileList = this.value;
                    return;
                }
            }
        }
    },
    mounted() {
        this.fileList = this.value;
    },
    data() {
        return {
            action: process.env.NODE_ENV == 'production' ? '/store/file/uploadFileZip' : "/api/file/uploadFileZip",
            data: {},
            headers: { batoken: this.$store.state.token },
            fileList: [],
            previewUrl: "",
            previewShow: false
        };
    },
    methods: {
        onPreview(file) {
            this.previewUrl = file.url;
            this.previewShow = true;
        },
        emitName() {
            this.$emit(
                "change",
                this.fileList.map(item => {
                    if (item.response) {
                        return {
                            url:
                                this.$store.getters.img_base_url +
                                (item.response.data.file_path || item.response.data.file.url),
                            name: item.name,
                            file_path: (item.response.data.file_path || item.response.data.file.url)
                        };
                    } else {
                        return {
                            url: item.url,
                            name: item.name
                        };
                    }
                })
            );
        },
        onRemove(file, fileList) {
            this.fileList = fileList;
            this.$emit(
                "change",
                fileList.map(item => {
                    if (item.response) {
                        return {
                            url:
                                this.$store.getters.img_base_url +
                                item.response.data.file.url,
                            name: item.name
                        };
                    } else {
                        return {
                            url: item.url,
                            name: item.name
                        };
                    }
                })
            );
        },
        onSuccess(response, file, fileList) {
            if (response.code === 1) {
                this.$message.success(response.msg);
                this.fileList = fileList;
                if (fileList.filter(item => item.status === 'success').length === fileList.length) {
                    this.$emit(
                        "change",
                        fileList.map(item => {
                            if (item.response) {
                                console.log(item);
                                return {
                                    url:
                                        this.$store.getters.img_base_url +
                                        (item.response?.data.file_path || item.data?.file_path || item.response.data.file.url),
                                    name: item.name,
                                    file_path: (item.response?.data?.file_path || item.response.data.file.url)
                                };
                            } else {
                                return {
                                    url: item.url,
                                    name: item.name
                                };
                            }
                        })
                    );
                }
            } else {
                this.$message.error(response.msg);
            }
        },
        beforeUpload(file) {
            const isAllowSize = file.size / 1024 / 1024 < 4;
            const isAllowType =
                file.type === "image/jpeg" || file.type === "image/png";
            if (!isAllowSize) {
                this.$message.error("上传文件大小不能超过4M!");
            }
            if (!isAllowType) {
                this.$message.error("只能上传jpg或png格式");
            }
            return isAllowSize && isAllowType;
        },
        onExceed(files, fileList) {
            this.$message.error("上传图片数量不能超过" + this.limit + "张");
        }
    }
};
</script>

<style >
.upload-image .image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-image .image:hover .mask {
    display: flex;
}

.upload-image .mask {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, .5);
    z-index: 9;
    display: none;
}</style>