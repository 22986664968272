<template>
  <div class="nmw-file-upload">
    <div class="list">
      <div class="item" v-for="(item, index) in fileList" :key="`file-${item.url}`">
        <el-link class="line-one" @click.native="onPreview(item)">{{ item.name }}</el-link>
        <span class="flex ml-10 button" style="width: 50px; gap: 10px">
          <el-popover placement="top" width="400" v-model="item.visible">
            <div class="flex">
              <el-input placeholder="请输入修改名称" size="mini" v-model="item.copyname"></el-input>
              <el-button
                class="ml-10"
                type="primary"
                size="mini"
                @click="
                  item.name = item.copyname;
                  item.visible = false;
                "
                >确定</el-button
              >
            </div>
            <i v-if="!disabled" class="el-icon-edit" slot="reference"></i>
          </el-popover>
          <i v-if="!disabled" class="el-icon-delete" @click="fileList.splice(index, 1)"></i>
        </span>
      </div>
    </div>
    <el-upload :multiple="multiple" :disabled="disabled" :limit="limit" :file-list="fileList" :on-exceed="handleExceed" :show-file-list="false" :http-request="httpRequest" :on-remove="handleRemove" :on-preview="onPreview" accept="application/pdf,application/msword,application/vnd.ms-excel,text/plain,image/jpeg,aplication/zip,image/gif,image/*" action="" class="upload-demo">
      <el-button v-if="!disabled" size="small" type="primary" ref="uploadBtn">添加附件</el-button>
      <p v-if="disabled && !fileList.length">无</p>
      <div slot="tip" class="el-upload__tip">
        {{ tips }}
      </div>
    </el-upload>
  </div>
</template>

<script>
import { uploadFile } from "@/api/common";
import { downloadFile } from "@/utils/common";

export default {
  props: {
    value: {
      type: Array | Object | Boolean,
      required: true,
      default: () => [],
    },
    tips: {
      type: String,
      default: " （单个附件最大支持20M，支持格式：PDF、Word、Excel、Txt、JPG、PNG、BMP、GIF、RAR、ZIP）",
    },
    limit: {
      type: Number,
      default: 1,
    },
    multiple: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      fileList: [],
    };
  },
  watch: {
    value(val) {
      this.fileList = Array.isArray(val) ? val : [];
    },
    fileList(val) {
      this.$emit("input", val);
    },
  },

  methods: {
    handleRemove(file, fileList) {
      const index = this.fileList.findIndex((d) => {
        return (d.url = file.url);
      });
      this.fileList.splice(index, 1);
    },
    httpRequest({ file }) {
      const form = new FormData();
      form.append("file", file);
      uploadFile(form).then((res) => {
        this.fileList.push({
          name: file.name,
          url: res.data.file_url,
        });
      });
    },
    handleExceed() {
      this.$message.warning(`当前限制限制 ${this.limit} 个文件`);
    },
    onPreview({ url, name }) {
      downloadFile(url, name);
    },
  },
};
</script>

<style lang="scss" scoped>
.nmw-file-upload {
  .button i {
    cursor: pointer;
    font-size: 16px;
  }

  .button i:hover {
    color: #409eff;
  }
  .list {
    display: flex;
    flex-direction: column;
  }
  .item {
    display: flex;
  }
}
</style>
