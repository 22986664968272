import request from "@/utils/request";
//账号相关接口
/**
 * 账号列表
 */
export function getStoreAccount(data) {
  return request({
    url: "StoreAccount/list",
    method: "post",
    data,
  });
}
/**
 * 账号启动与禁用
 */
export function setAlowStoreAccount(data) {
  return request({
    url: "StoreAccount/changeAllow",
    method: "post",
    data,
  });
}
/**
 * 账号详情
 */
export function getStoreAccountDetail(data) {
  return request({
    url: "StoreAccount/detail",
    method: "post",
    data,
  });
}

/**
 * 所有账号
 */
export function getStoreAccountAll() {
  return request({
    url: "StoreAccount/allList",
    method: "post",
  });
}
