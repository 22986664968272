<template>
  <el-dialog :close-on-click-modal="false" title="新增待办" :visible.sync="dialogVisible" width="500px" @closed="dialogClose">
    <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="mini">
      <el-form-item label="被指派人" prop="wait_account_id">
        <el-select multiple v-model="form.wait_account_id" filterable placeholder="请选择沟通人" style="width: 100%">
          <el-option v-for="item in storeAccount" :key="item.id" :label="item.real_name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="内容 " prop="content">
        <el-input v-model="form.content" type="textarea"></el-input>
      </el-form-item>
      <el-form-item label="图片">
        <el-upload ref="upload" :file-list="fileList" :http-request="handleUpload" accept=".jpg,.jpeg,.png,.JPG,.JPEG" action="#" list-type="picture-card">
          <i class="el-icon-plus"></i>
          <div slot="file" slot-scope="{ file }" style="height: 100%">
            <img :src="file.create_time ? $store.getters.img_base_url + file.file_url : file.url" alt="" class="el-upload-list__item-thumbnail" />
            <span class="el-upload-list__item-actions">
              <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
      <el-button size="mini" type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { addImgs } from "@/api/common";
import { throttle } from "lodash";
import { getStoreAccount } from "@/api/staff";
import { updateWait } from "@/api/customer";

export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        imgs: [],
        content: "",
        wait_account_id: [],
        user_id: "",
      },
      fileList: [], //用于存储文件 对比删除
      storeAccount: [], // 员工
      rules: {
        wait_account_id: [{ required: true, message: "请选择委托人", trigger: "change" }],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getStoreAccount();
  },
  methods: {
    async getStoreAccount() {
      const {
        data: { list },
      } = await getStoreAccount({
        is_daiban: 1,
      });
      this.storeAccount = list;
    },
    async handleUpload(e) {
      const form = new FormData();
      form.append("file_url[]", e.file);
      form.append("file_type", 1);
      const {
        data: { ids },
      } = await addImgs(form);
      this.form.imgs.push(ids[0]);
      this.fileList.push({
        url: URL.createObjectURL(e.file),
        uid: e.file.uid,
      });
    },
    dialogClose() {
      this.$refs.form.resetFields();
      this.fileList = [];
      this.form = this.$options.data().form;
    },
    handleRemove(file) {
      console.log(file, "file");
      const index = this.fileList.findIndex((item) => item.uid === file.uid);
      if (index !== -1) {
        this.fileList.splice(index, 1);
        this.form.imgs.splice(index, 1);
      }
    },
    edit(row) {
      this.dialogVisible = true;
      this.form.user_id = row.store_account_id;
      this.form.content = row.content;
      this.form.wait_account_id = row.wait_account.map((item) => item.id);
      this.form.imgs = row.img_array.map((item) => item.id);
      this.form.id = row.id;
      this.fileList = row.img_array.map((item) => {
        return {
          url: this.$store.getters.img_base_url + item.file_url,
          uid: item.id,
        };
      });
    },
    remove(file, fileList) {
      console.log(file, fileList);
    },
    submit: throttle(
      async function () {
        try {
          this.$refs.form.validate(async (valid) => {
            if (valid) {
              this.$loading();
              const { message } = await updateWait({
                ...this.form,
                user_id: this.user_id,
                imgs: this.form.imgs.toString(),
              });
              this.$emit("getList");
              this.$message.success(message);
            }
          });
        } finally {
          this.dialogVisible = false;
          this.$loading().close();
        }
      },
      1000,
      { trailing: false, leading: true }
    ),
  },
};
</script>
