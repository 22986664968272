<template>
  <div class="navbar">
    <hamburger :is-active="false" class="hamburger-container" @toggleClick="toggleSideBar" />
    <!-- sidebar.opened -->
    <breadcrumb class="breadcrumb-container" />
    <div class="right-menu">
      <div class="storeName">Hi,欢迎回来：{{ $store.state.user?.storeInfo?.account?.account_name }},当前所在店铺：<span
          style="color: #3f3e3e;font-weight: bold">{{ $store.state.user?.storeInfo?.store_name || '' }}</span></div>
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <img src="../../../src/assets/img/logo.png" class="user-avatar">
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <router-link to="/">
            <el-dropdown-item>
              首页
            </el-dropdown-item>
          </router-link>
          <el-dropdown-item>
            <div @click="switchStore">
              切换店铺
            </div>
          </el-dropdown-item>
          <router-link to="/commodity/collect_goods" v-if="$store.getters.storeInfo.pid == 0">
            <el-dropdown-item>
              采集商品
            </el-dropdown-item>
          </router-link>
          <router-link to="/program/system-cfg">
            <el-dropdown-item>
              系统设置
            </el-dropdown-item>
          </router-link>
          <el-dropdown-item divided @click.native="logout">
            <span style="display:block;">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'

export default {
  components: {
    Breadcrumb,
    Hamburger
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar'
    ])
  },
  methods: {
    async switchStore() {
      await this.$store.dispatch('user/switchStore')
      this.$router.replace('/selectStore')
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login`)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, .08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    display: flex;

    .storeName {
      font-size: 13px;
      color: #5a5e66;
      margin-right: 10px;
    }

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
<style>
.right-menu .el-dropdown-menu__item--divided:before {
  display: none !important;
}
</style>
