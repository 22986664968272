<template>
  <div class="sss">
    <div style="color: #333">总条数:{{ total }}</div>
    <el-pagination
      background
      @size-change="hadnleCurrentSizes"
      @current-change="handleCurrentChange"
      :page-sizes="[10,20,30,50]"
      :page-size="Number(pageSize)"
      layout="prev, pager, next, jumper,sizes"
      :total="total"
      :current-page="current"
    >

    </el-pagination>
  </div>
</template>
<script>
export default {
  props: {
    current:Number,
    pageSize: Number|String,
    total: Number,
  },
  data() {
    return {};
  },
  methods: {
    hadnleCurrentSizes(e){
      this.$emit("sizeChange", e);
    },
    handleCurrentChange(e) {
      console.log(e)
      this.$emit('update:current', e)
      this.$emit("getData", e);
    },
  },
};
</script>
<style lang='scss' scoped>
.sss {
  padding: 20px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0;
}
</style>
