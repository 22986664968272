<template>
    <el-image-viewer style="z-index:9999;" class="preview-dialog" v-if="dialogVisible" :urlList="url" :initialIndex="0"
        :on-close="close">
    </el-image-viewer>
</template>
<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
    name: 'preview-dialog',
    components: {
        ElImageViewer
    },
    data() {
        return {
            dialogVisible: false,
        }
    },
    props: {
        url:Array
    },
    methods: {
        setImg(url) {
            this.url = url
        },
        show() {
            this.dialogVisible = true
        },
        close() {
            this.dialogVisible = false
        }
    },
}
</script>
<style>
.el-mask {
    background: rgba(0, 0, 0, .5);
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999 !important;
    text-align: center;
}

.LPreviewImage .el-image__error {
    height: 200px;
    width: 200px;
}

.flex {
    display: flex;
}

.jus-c {
    justify-content: center;
}
</style>