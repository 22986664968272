/**
 * 此模块用于保存列表页的搜索记录以及分页记录，从详情页返回列表页时保留记录
 * @returns {{pagePath: string, currentPage: number, pageParam: {}}}
 */

function restInfo() {
  return {
    pagePath: '',
    pageParam: {},
    currentPage: 1,
  }
}

const state = restInfo()
const getters = {}
const mutations = {
  SET_PARAMS(state, data) {
    state.pageParam = data
  },
  SET_PAGE(state, data) {
    state.currentPage = data
  },
  SET_PATH(state, data) {
    state.pagePath = data
  },
  REST_INFO(state) {
    state.pagePath = ''
    state.pageParam = {}
    state.currentPage = 1
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

