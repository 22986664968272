//深拷贝
export function deepClone(arr) {
  if (arr && typeof arr === "object") {
    const target = arr.constructor === Array ? [] : {};
    Object.keys(arr).forEach((key) => {
      if (arr[key] && typeof arr[key] === "object") {
        target[key] = deepClone(arr[key]);
      } else {
        target[key] = arr[key];
      }
    });
    return target;
  } else {
    throw new Error("参数不是对象或数组");
  }
}

//计算商品基于当前仓库的库存数以及单位所能处理的最大数量
export function computedGoodsStockNum(nums, rate, allnums) {
  let n = parseInt(nums);
  let r = parseInt(rate);
  let a = parseInt(allnums);
  console.log(n, r, a);
  if (n * r <= a) {
    return n;
  } else {
    return parseInt(a / r);
  }
}
/**
 * 根据指定数组字段返回过滤后的数组
 * @param {需要过滤的数组} arr
 * @param {需要过滤出的字符串} fieldArr
 * @returns 返回后过滤后的数组
 */
export function filterFields(arr, fieldArr) {
  const resultArr = [];
  arr.forEach((item, i) => {
    let index = i;
    Object.keys(item).forEach((key) => {
      if (fieldArr.includes(key)) {
        if (index !== i) {
          resultArr[i][key] = item[key];
        } else {
          resultArr.push({
            [key]: item[key],
          });
          index = "";
        }
      }
    });
  });
  console.log(resultArr,'resultArr')
  return resultArr;
}

export function downloadFile(url, name = "文件") {
  const http = new XMLHttpRequest();
  http.open("GET", url, true);
  http.responseType = "blob";
  http.onload = function () {
    if (http.status === 200) {
      const url = window.URL.createObjectURL(http.response);
      const a = document.createElement("a");
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
  http.onerror = function () {
    ELEMENT.Message.error('下载失败')
  };
  http.send();
}
// 返回图片第一帧
export function getVideoFirstImg(vedioFile,callback) {
  const file = vedioFile;
  const blob = new Blob([file]), // 文件转化成二进制文件
    url = URL.createObjectURL(blob); //转化成url

  //创建video标签
  const videoElement = document.createElement("video");
  videoElement.src = url;
  videoElement.setAttribute("preload", "auto");
  videoElement.currentTime = 1;
  videoElement.addEventListener("loadeddata", function () {
    const canvas = document.createElement("canvas");
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    canvas
      .getContext("2d")
      .drawImage(videoElement, 0, 0, canvas.width, canvas.height);
    const firstFrame = canvas.toDataURL("image/png"); //第一帧图片url
    callback(firstFrame)
  });
}
