// import Vue from "vue";
// Echarts
import ECharts from "vue-echarts";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart, LineChart } from "echarts/charts";
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    LegendComponent,
    DataZoomComponent,
    BrushComponent,
    ToolboxComponent
} from "echarts/components";
use([
    CanvasRenderer,
    PieChart,
    BarChart,
    LineChart,
    GridComponent,
    TooltipComponent,
    TitleComponent,
    LegendComponent,
    DataZoomComponent,
    BrushComponent,
    ToolboxComponent
]);
Vue.component("v-chart", ECharts)
// import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
// import "ant-design-vue/dist/antd.css";
// import locale from 'element-ui/lib/locale/lang/en' //  lang i18n
import 'normalize.css'
import { DatePicker } from "ant-design-vue";
Vue.use(DatePicker);
import "@/styles/index.scss"; // global css


import App from "./App";
import store from "./store";
import router from "./router"; // 引入路由
import "moment/locale/zh-cn";
import "@/icons"; // icon
import "@/permission"; // 权限控制
import '@/components/index' //全局组件


import * as filters from "@/utils/filters"; //过滤器
import * as instructions from "@/utils/instructions"; //指令
import { deepClone } from "@/utils/common";
Vue.prototype.$deepClone = deepClone;
import previewImage from "./components/previewImage/index.js";
Vue.use(previewImage)
import popover from "./components/popover.vue";
Vue.component('popover', popover)

// 获取当前路由下面的权限
import { getRouteAuth, auth } from '@/utils/buttonAuth'
Vue.prototype.$getRouteAuth = (path = router.currentRoute.path) => new getRouteAuth(path)
Vue.prototype.$auth = auth

/**
 * 全局过滤器
 */
Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key]); //插入过滤器名和对应方法
});
/**
 * 全局功能按钮权限指令
 */
Vue.directive("auth", {
    inserted: function (el, binding) {
        store.dispatch("user/authVerification", binding.value).then((res) => {
            if (!res) {
                console.log(res);
                el.parentNode && el.parentNode.removeChild(el);
            }
        });
    },
});

Vue.directive('debounce', {
    inserted: function (el, binding, vnode, oldVnode) {
        let timeout = null;
        el.addEventListener('click', function () {
            if (timeout) return
            binding.value();
            timeout = setTimeout(() => {
                timeout = null;
                clearTimeout(timeout);
            }, binding.arg);
        });
    }
})
Vue.directive("inputLimit", instructions.inputLimt);

if (process.env.NODE_ENV === "production") {
    // const { mockXHR } = require("../mock");
    // mockXHR();
}

Vue.config.productionTip = false;

Vue.prototype.$filterSeach = (e, list, keys) => {
    list = list.filter(item => {
        for (const key of keys) {
            return item[key].indexOf(e) > -1
        }
    })
    return list
}


new Vue({
    el: "#app",
    router,
    store,
    render: (h) => h(App),
});
